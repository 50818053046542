/* google-font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&amp;family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&amp;family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 10px;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  background: #fff;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  color: #1d1d1d;
  font-family: 'League Spartan', sans-serif;
  font-weight: 700;
  margin: 0;
}

h1 {
  font-size: 7.6rem;
}
@media (max-width: 767px) {
  h1 {
    font-size: 4rem;
  }
}

h2 {
  font-size: 5.6rem;
}

h3 {
  font-size: 3.6rem;
}

h4 {
  font-size: 3.4rem;
}

h5 {
  font-size: 3rem;
  margin-bottom: 0;
}

a {
  font-size: 1.8rem;
  font-weight: 700;
  color: #1d1d1d;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
}
a .list-text {
  color: #ffffff;
  font-size: 1.6rem;
}
a span {
  font-size: 14px;
  font-weight: 500;
}

p {
  font-size: 15px;
  color: #797979;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  margin-bottom: 0;
}

ul {
  padding-left: 0;
  margin: 0;
}

li {
  list-style: none;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

iframe {
  width: 100%;
}

.container {
  max-width: 132.1rem;
}
@media only screen and (min-width: 767px) and (max-width: 1200px) {
  .container {
    padding: 0 2rem;
  }
}

.box-shadows {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

input,
button {
  border: none;
  outline: none;
  background: transparent;
}

input {
  font-size: 1.3rem;
}

input[type='checkbox'] {
  accent-color: #34a853;
}

button {
  border: none;
  outline: none;
  background: transparent;
}

.shop-btn {
  padding: 1.2rem 3.4rem;
  margin: 4rem 0;
  text-align: center;
  border-radius: 0.4rem;
  display: inline-block;
  background: #34a853;
  font-size: 1.4rem;
  font-weight: 500;
  position: relative;
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  transition: all 0.3s;
  overflow: hidden;
  z-index: 1;
}

.shop-btn-red {
  background-color: #fe0600;
}

.shop-btn-yellow {
  background-color: #ffbb38;
  color: #1d1d1d;
}

/* .shop-btn-red {
  padding: 1.2rem 3.4rem;
  margin: 4rem 4rem;
  text-align: center;
  border-radius: 0.4rem;
  display: inline-block;
  background: #fe0600;
  font-size: 1.4rem;
  font-weight: 500;
  position: relative;
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  transition: all 0.3s;
  overflow: hidden;
  z-index: 1;
} */
.shop-btn::after {
  content: ' ';
  width: 0%;
  height: 100%;
  background: #1d1d1d;
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
  top: 0;
  z-index: -3;
}
.shop-btn-red::after {
  content: ' ';
  width: 0%;
  height: 100%;
  background: #1d1d1d;
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
  top: 0;
  z-index: -3;
}
/* .shop-btn-red::after {
  content: ' ';
  width: 0%;
  height: 100%;
  background: #1d1d1d;
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
  top: 0;
  z-index: -3;
} */
.shop-btn svg {
  fill: #ffffff;
  transition: all 0.5s;
}
.shop-btn span {
  margin-left: 8px;
  font-size: 1.5rem;
  width: 100%;
  z-index: 1;
  transition: all 0.4s ease-in-out;
}
.shop-btn:hover {
  color: #ffffff;
  background-color: #34a853;
}
/* .shop-btn-red:hover {
  color: #ffffff;
  background-color: #fe0600;
} */
.shop-btn:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}
/* .shop-btn-red:hover::after {
  right: auto;
  left: 0;
  width: 100%;
} */
.shop-btn:hover svg {
  fill: currentColor;
  fill: #ffffff;
  transform: translate(5px);
}
/* .shop-btn-red:hover svg {
  fill: #ffffff;
  transform: translate(5px);
} */
.shop-btn:hover span {
  color: #ffffff;
}
/* .shop-btn-red:hover span {
  color: #ffffff;
} */
.product {
  padding: 7rem 0 8rem 0;
}
@media (max-width: 1023px) {
  .product {
    padding: 3rem 0;
  }
}

.product-wrapper {
  width: 100%;
  height: 42rem;
  padding: 0 0 2rem 0;
  border-radius: 4px;
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
  transition: all 0.2s;
  border: 1px solid #ffffff;
  overflow: hidden;
}
.product-wrapper .product-img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  height: 21rem;
  padding-top: 3.2rem;
  margin-bottom: 3.5rem;
  position: relative;
}
.product-wrapper .product-img img {
  transform: scale(1);
  height: 100%;
  width: 100%;
  object-fit: contain;
  transition: all 0.4s ease-in-out;
}
.product-wrapper .product-img .product-cart-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: absolute;
  top: 2rem;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.product-wrapper .product-img .product-cart-items .cart-item svg rect {
  fill: currentColor;
  fill: #d6eedd;
  transition: all 0.2s linear;
}
.product-wrapper .product-img .product-cart-items .cart-item svg path {
  fill: currentColor;
  fill: #1d1d1d;
}
.product-wrapper .product-img .product-cart-items .cart-item:hover svg rect {
  fill: currentColor;
  fill: #34a853;
}
.product-wrapper .product-img .product-cart-items .cart-item:hover svg path {
  fill: currentColor;
  fill: #ffffff;
}
.product-wrapper .product-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  margin: 0rem;
  text-align: center;
  padding: 0 3rem 3rem;
}
.product-wrapper .product-info .ratings {
  margin: 0.6rem 0;
}
.product-wrapper .product-details {
  font-family: 'League Spartan', sans-serif;
  font-size: 1.6rem;
}
.product-wrapper .product-cart-btn {
  transition: all 0.4s;
  width: 100%;
}
.product-wrapper .product-cart-btn .product-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.product-wrapper .product-cart-btn .product-btn .btn-text {
  font-weight: 600;
}
.product-wrapper:hover {
  border: 1px solid #34a853;
}
.product-wrapper:hover .product-img img {
  transform: scale(1.1);
}
.product-wrapper:hover .product-cart-items {
  right: 2rem;
  opacity: 1;
  visibility: visible;
}
.product-wrapper:hover .product-cart-items .product-cart-btn {
  border-bottom-right-radius: 1.2rem;
}
@media (max-width: 767px) {
  .product-wrapper .price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .product-wrapper .price span {
    font-size: 2rem;
  }
}

.price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;
}
.price .price-cut {
  text-decoration: line-through;
}
.price .new-price {
  color: #34a853;
}
.price span {
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
  color: #797979;
  font-weight: 600;
}
.product-btn {
  padding: 1.2rem 1.6rem 1.2rem 2.5rem;
  background: rgba(52, 168, 83, 0.2);
  color: #34a853;
  font-size: 1.6rem;
  width: 100%;
  border-radius: 4px;
  transition: all 0.4s;
}
.product-btn svg {
  fill: currentColor;
  fill: #34a853;
  transition: all 0.4s;
}
.product-btn:hover {
  background-color: #34a853;
  color: #ffffff;
}
.product-btn:hover svg {
  fill: #ffffff;
}

.section-title {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
}
.section-title h3 {
  font-weight: 600;
}
.section-title .view {
  font-family: 'League Spartan', sans-serif;
  position: relative;
  font-weight: 600;
}
.section-title .view::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #1d1d1d;
  top: 2.2rem;
  left: 2px;
  transition: all 0.2s;
  opacity: 0;
  visibility: hidden;
}
.section-title .view:hover::before {
  opacity: 1;
  visibility: visible;
  width: 7rem;
}
@media (max-width: 767px) {
  .section-title h3 {
    font-size: 3rem;
  }
}

.blog-item,
.cart-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  text-align: center;
}
.blog-item p,
.cart-content p {
  font-size: 2rem;
  color: #1d1d1d;
  font-weight: 600;
}
.blog-item .shop-btn,
.cart-content .shop-btn {
  margin: 0;
}

.modal-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.modal-wrapper {
  margin-left: 33vw;
  margin-top: 10vh;
}

.modal-wrapper .anywhere-away {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.37);
  width: 100%;
  height: 100%;
}

.modal-main {
  position: relative;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
}
.modal-main .wrapper-close-btn {
  position: absolute;
  right: 3rem;
  top: 3rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}
.modal-main .wrapper-close-btn svg {
  fill: currentColor;
  fill: red;
  stroke: red;
}
.modal-main .wrapper-main .divider {
  height: 3rem;
  width: 1px;
  background: #e8e8e8;
}

.header-top-section {
  border-bottom: 1px solid rgba(52, 168, 83, 0.06);
  background: rgba(52, 168, 83, 0.2);
}

.header-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
}
.header-top .header-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.header-top .header-contact .header-top-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.header-top .header-contact .header-top-contact .top-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.header-top .header-contact .divider {
  height: 1.4rem;
  width: 1px;
  background: rgba(121, 121, 121, 0.18);
}
.header-top .header-contact .contact-number {
  color: #34a853;
  font-weight: 700;
}
@media (max-width: 1023px) {
  .header-top {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
}

.header-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  padding: 0.5rem 0 0.5rem 0;
}
.header-center .header-search-btn {
  width: 51.6rem;
  height: 4.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  border: 1px solid rgba(52, 168, 83, 0.18);
  overflow: hidden;
  font-size: 1.2rem;
  border-radius: 4px;
}
.header-center .header-search-btn input {
  padding: 1.2rem;
  color: #8e8e8e;
}
.header-center .header-search-btn .divider {
  width: 1px;
  background: #eee;
  height: 2.5rem;
}
.header-center .header-search-btn #allcat {
  flex: 1 1 0%;
}
.header-center .header-search-btn button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  padding: 0 2rem;
  color: #797979;
  height: 100%;
  background-color: transparent;
}
.header-center .header-search-btn .shop-btn {
  padding: 1.4rem 2.2rem;
  margin: 0;
}
.header-center .header-search-btn .shop-btn::after {
  display: none;
}
.header-center .header-cart-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
}
.header-center .header-cart-items .cart-item {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: 5px;
}
.header-center .header-cart-items .cart-item .cart-text {
  font-size: 1.6rem;
  color: #797979;
}
.header-center .header-cart-items .header-cart {
  position: relative;
}
.header-center .header-cart-items .header-cart .cart-submenu {
  position: absolute;
  width: 30rem;
  background: #ffffff;
  z-index: 2;
  left: -15rem;
  top: 5rem;
  border-top: 3px solid #34a853;
  border-radius: 0.5rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.header-center .header-cart-items .header-cart .cart-submenu .wrapper-title {
  font-size: 2rem;
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-item {
  padding: 1rem;
  width: 100%;
  height: 30.1rem;
  overflow-y: scroll;
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-item
  .wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0px;
  margin-top: 2rem;
  width: 100%;
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-item
  .wrapper
  .wrapper-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-item
  .wrapper
  .wrapper-item
  .wrapper-title,
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-item
  .wrapper
  .wrapper-item
  .new-price {
  font-size: 1.5rem;
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-item
  .wrapper
  .wrapper-item
  .wrapper-img {
  width: 6.5rem;
  height: auto;
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-item
  .wrapper
  .wrapper-item
  .wrapper-img
  img {
  width: 100%;
  height: 100%;
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-item
  .wrapper
  .wrapper-item
  .wrapper-content
  .price {
  text-align: left;
  justify-content: unset;
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-item::-webkit-scrollbar {
  width: 2px;
  height: 15rem;
  background: rgba(52, 168, 83, 0.06);
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-item::-webkit-scrollbar-track {
  background: #e8e8e8;
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-item::-webkit-scrollbar-thumb {
  background: #8e8e8e;
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-section {
  width: 100%;
  padding: 2rem;
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-section
  .wrapper-line {
  width: 100%;
  height: 1px;
  background: rgba(52, 168, 83, 0.06);
  border-radius: 1rem;
  margin-bottom: 1rem;
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-section
  .wrapper-subtotal {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-section
  .cart-btn {
  width: 100%;
  margin-top: 2rem;
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-section
  .cart-btn
  .shop-btn {
  background: #ddd;
  margin: 0.5rem 0;
  width: 100%;
  border-radius: 0.5rem;
  color: #1d1d1d;
  font-weight: 600;
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-section
  .cart-btn
  .shop-btn::after {
  display: none;
}
.header-center
  .header-cart-items
  .header-cart
  .cart-submenu
  .cart-wrapper-section
  .cart-btn
  .checkout-btn {
  background: #34a853;
  color: #ffffff;
}
.header-center .header-cart-items .header-cart:hover .cart-submenu {
  opacity: 1;
  visibility: visible;
}
.header-center .header-cart-items .header-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  width: 52px;
  height: 52px;
  background: #ffbb38;
  border-radius: 50%;
}
.header-center .header-cart-items .header-search .modal-main {
  height: 23.8rem;
}
.header-center .header-cart-items .header-search .modal-main .wrapper-main {
  width: 62rem;
  height: 6rem;
  border-radius: 3rem;
  border: 1px solid #34a853;
  overflow: hidden;
}
.header-center .header-cart-items .header-search .modal-main .search-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  width: 100%;
  height: 100%;
}
.header-center
  .header-cart-items
  .header-search
  .modal-main
  .search-section
  input,
.header-center
  .header-cart-items
  .header-search
  .modal-main
  .search-section
  button {
  border: none;
  outline: none;
  background: transparent;
  height: 100%;
  padding: 0 2rem;
  font-size: 1.5rem;
  font-weight: 500;
  color: #797979;
  flex: 1 1 0%;
}
.header-center
  .header-cart-items
  .header-search
  .modal-main
  .search-section
  input {
  color: #34a853;
}
.header-center
  .header-cart-items
  .header-search
  .modal-main
  .search-section
  .shop-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 2rem 3rem;
}
.header-center
  .header-cart-items
  .header-search
  .modal-main
  .search-section
  .shop-btn::after {
  display: none;
}
.header-center .header-cart-items .header-search .modal-wrapper.active {
  display: block;
}
.header-center .header-cart-items .header-user svg {
  fill: currentColor;
  fill: #797979;
}

.slectbox-body .country-options {
  position: absolute;
  z-index: 5;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 0px 0;
  margin-top: 13px;
  width: 22.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border: 1px solid rgba(121, 121, 121, 0.18);
  z-index: 20;
  overflow: hidden;
}
.slectbox-body .country-options .all-option {
  cursor: pointer;
  padding: 9px 16px;
}
.slectbox-body .country-options .all-option:hover {
  background: rgba(121, 121, 121, 0.18);
}
.slectbox-body .country-options .all-option:hover .option-text {
  font-weight: 700;
}
.slectbox-body .clickAway {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  display: block;
}

.mobile-menu {
  padding: 0px 10px 10px 10px;
  background-color: rgba(52, 168, 83, 0.06);
}

.offcanvas.offcanvas-start {
  width: 280px;
}

.offcanvas-body {
  padding: 2.5rem 1rem 0rem 2rem;
}
.offcanvas-body .header-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  margin: 0 0 2rem 0;
}
.offcanvas-body .header-top .header-cart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}
.offcanvas-body .header-top .shop-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
}
.offcanvas-body .header-top .shop-btn .btn-close {
  --bs-btn-close-bg: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="%23FFF" width="24px" height="24px"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>');
  font-size: 2rem;
}
.offcanvas-body .header-top .shop-btn i {
  color: #ffffff;
}
.offcanvas-body .header-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  background: #ffffff;
  border: 1px solid rgba(52, 168, 83, 0.2);
  margin-bottom: 2rem;
  border-radius: 0.5rem;
  overflow: hidden;
}
.offcanvas-body .header-input input {
  background: #ffffff;
  padding: 1rem 0 1rem 1rem;
  flex: 1 1 0%;
}
.offcanvas-body .header-input span {
  height: 4rem;
  width: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  background: #34a853;
}
.offcanvas-body .category-dropdown {
  margin-top: 2rem;
}
.offcanvas-body .category-dropdown .category-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 3rem;
}
.offcanvas-body .category-dropdown .category-list .category-list-item {
  width: 100%;
}
.offcanvas-body
  .category-dropdown
  .category-list
  .dropdown-item
  .dropdown-list-item {
  align-items: center;
  gap: 2rem;
}
.offcanvas-body
  .category-dropdown
  .category-list
  .dropdown-item
  .dropdown-list-item
  .dropdown-img {
  width: 2.5rem;
  height: 2.5rem;
}
.offcanvas-body
  .category-dropdown
  .category-list
  .dropdown-item
  .dropdown-list-item
  .dropdown-img
  svg {
  fill: #1d1d1d;
  line-height: 0;
}
.offcanvas-body
  .category-dropdown
  .category-list
  .dropdown-item
  .drop-down-list-icon
  span
  svg,
.offcanvas-body
  .category-dropdown
  .category-list
  .dropdown-item
  .drop-down-list-icon
  span
  rect {
  fill: currentColor;
  fill: #34a853;
}

.header-bottom {
  background: #34a853;
  padding: 10px 0;
}

.header-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  position: relative;
}
.header-nav .header-nav-menu-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: unset;
  gap: 0px;
  gap: 5rem;
}
.header-nav .category-menu-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: unset;
  gap: 0px;
  cursor: pointer;
  width: 27rem;
  height: 5.3rem;
  background: #ffffff;
  color: #1d1d1d;
  border-radius: 5px 5px 0px 0px;
}
.header-nav .category-menu-section .list-text {
  color: #1d1d1d;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}
.header-nav .category-menu-section .empty {
  width: 100%;
  height: 100%;
  display: none;
  z-index: 2;
  left: 0;
  top: 0;
}
.header-nav .category-menu-section .empty.active {
  display: block;
}
.header-nav .category-menu-section .category-item {
  background: #ffffff;
  width: 100%;
  height: 100%;
  margin: 5px 0 0 0;
  border: none;
  padding: 1.5rem 1.8rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 1.4rem;
  font-weight: 600;
}
.header-nav .category-menu-section .category-item i {
  font-size: 10px;
}
.header-nav .category-menu-section .category-item .category-img {
  padding-right: 1.6rem;
}
.header-nav .category-menu-section .category-dropdown {
  overflow: hidden;
  width: 100%;
  max-height: 0;
  transition: all 0.3s;
  left: 0;
  top: 5.3rem;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.header-nav .category-menu-section .category-dropdown .category-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0;
}
.header-nav .category-menu-section .category-dropdown .category-list-item {
  background: #ffffff;
  padding: 0.8rem 2rem;
  border-top: 1px solid rgba(52, 168, 83, 0.06);
  transition: all 0.5s;
  width: 100%;
}
.header-nav
  .category-menu-section
  .category-dropdown
  .category-list-item:hover {
  background-color: #34a853;
}
.header-nav
  .category-menu-section
  .category-dropdown
  .category-list-item:hover
  .dropdown-text {
  color: #ffffff;
}
.header-nav
  .category-menu-section
  .category-dropdown
  .category-list-item:hover
  .dropdown-img
  svg,
.header-nav
  .category-menu-section
  .category-dropdown
  .category-list-item:hover
  .dropdown-img
  rect,
.header-nav
  .category-menu-section
  .category-dropdown
  .category-list-item:hover
  .drop-down-list-icon
  svg,
.header-nav
  .category-menu-section
  .category-dropdown
  .category-list-item:hover
  .drop-down-list-icon
  rect {
  fill: #ffffff;
}
.header-nav
  .category-menu-section
  .category-dropdown
  .category-list-item
  .drop-down-list-icon
  svg,
.header-nav
  .category-menu-section
  .category-dropdown
  .category-list-item
  .drop-down-list-icon
  rect {
  fill: #1d1d1d;
}
.header-nav
  .category-menu-section
  .category-dropdown
  .category-list-item
  .dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
}
.header-nav
  .category-menu-section
  .category-dropdown
  .category-list-item
  .dropdown-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  gap: 2.6rem;
}
.header-nav
  .category-menu-section
  .category-dropdown
  .category-list-item
  .dropdown-list-item
  .dropdown-img {
  width: 2rem;
  height: 2rem;
}
.header-nav
  .category-menu-section
  .category-dropdown
  .category-list-item
  .dropdown-list-item
  .dropdown-img
  img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.header-nav
  .category-menu-section
  .category-dropdown
  .category-list-item
  .dropdown-list-item
  .dropdown-text {
  font-size: 1.5rem;
  line-height: 1.3rem;
}
.header-nav .category-menu-section .open-dropdown {
  max-height: var(--max-height);
}
.header-nav .list-text {
  font-size: 1.4rem;
  color: #ffffff;
}
.header-nav .shop-text {
  color: #1d1d1d;
}
.header-nav .header-nav-menu .menu-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}
.header-nav .header-nav-menu .menu-list li {
  position: relative;
  margin-right: 2rem;
}
.header-nav .header-nav-menu .menu-list li:hover .header-sub-menu {
  visibility: visible;
  opacity: 1;
}
.header-nav .header-nav-menu .menu-list .header-sub-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5rem;
  width: 220px;
  position: absolute;
}
.header-nav .header-nav-menu .menu-list .header-sub-menu,
.header-nav .header-nav-menu .menu-list .shop-menu {
  position: absolute;
  background: #ffffff;
  visibility: hidden;
  z-index: 2;
  opacity: 0;
  top: 4.7rem;
  left: 0;
  pointer-events: auto;
  transition: all 0.5s ease-in-out;
  border-radius: 0.5rem;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 2rem;
}
.header-nav .header-nav-menu .menu-list .header-sub-menu a,
.header-nav .header-nav-menu .menu-list .shop-menu a {
  font-size: 1.5rem;
  font-weight: 400;
  color: #797979;
}
.header-nav .header-nav-menu .menu-list .header-sub-menu a:hover,
.header-nav .header-nav-menu .menu-list .shop-menu a:hover {
  color: #34a853;
}
.header-nav .header-nav-menu .menu-list .mega-menu {
  position: unset;
}
.header-nav .header-nav-menu .menu-list .mega-menu .shop-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 3rem;
  width: 100%;
  height: 29.5rem;
  padding: 0 3rem;
  top: 6.1rem;
}
.header-nav .header-nav-menu .menu-list .mega-menu .shop-menu .menu-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 0px;
  flex: 1 1 0%;
}
.header-nav
  .header-nav-menu
  .menu-list
  .mega-menu
  .shop-menu
  .menu-wrapper
  .menu-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}
.header-nav
  .header-nav-menu
  .menu-list
  .mega-menu
  .shop-menu
  .menu-wrapper
  .menu-list
  .menu-title {
  font-size: 1.8rem;
  font-weight: 600;
}
.header-nav
  .header-nav-menu
  .menu-list
  .mega-menu
  .shop-menu
  .menu-wrapper
  .menu-list
  ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}
.header-nav .header-nav-menu .menu-list .mega-menu .shop-menu .shop-menu-img {
  width: 28.4rem;
  height: 23.5rem;
}
.header-nav
  .header-nav-menu
  .menu-list
  .mega-menu
  .shop-menu
  .shop-menu-img
  img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.header-nav .header-nav-menu .menu-list .mega-menu:hover .shop-menu {
  visibility: visible;
  opacity: 1;
}
.header-nav .shop-btn {
  padding: 1.4rem 3rem;
  margin: 0px;
  text-align: center;
  border-radius: 0.4rem;
  display: inline-block;
  background: #ffbb38;
  font-size: 1.4rem;
  font-weight: 500;
  position: relative;
  color: #1d1d1d;
  font-family: 'Inter', sans-serif;
  transition: all 0.3s;
  overflow: hidden;
  font-weight: 600;
  margin-right: 1rem;
  /* margin-bottom: 1rem; */
}
.header-nav .shop-btn::after {
  content: ' ';
  width: 0%;
  height: 100%;
  background: #1d1d1d;
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
  top: 0;
  z-index: -3;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.header-nav .shop-btn svg {
  fill: #ffffff;
  transition: all 0.5s;
}
.header-nav .shop-btn span {
  margin-left: 8px;
  font-size: 1.5rem;
  width: 100%;
  z-index: 1;
  transition: all 0.4s ease-in-out;
}
.header-nav .shop-btn:hover {
  color: #ffffff;
  background-color: #ffbb38;
}
.header-nav .shop-btn:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}
.header-nav .shop-btn:hover svg {
  fill: currentColor;
  fill: #ffffff;
  transform: translate(5px);
}
.header-nav .shop-btn:hover span {
  color: #ffffff;
}
.header-nav .shop-btn .shop-list {
  color: #1d1d1d;
}
@media (max-width: 1200px) {
  .header-nav .header-nav-menu .menu-list {
    gap: 2rem;
  }
}

.dropdown-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
}
.dropdown-btn .dropdown-icon {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: unset;
  gap: 1rem;
}
.dropdown-btn .dropdown-icon svg {
  fill: currentColor;
  fill: #1d1d1d;
}
.dropdown-btn .dropdown-text {
  font-size: 1.6rem;
  color: #ffffff;
}

/* .hero {
  background: url("../assets/images/homepage-one/hero-bg.webp") no-repeat center/cover;
} */
.hero .swiper-pagination-bullets {
  bottom: 3rem;
}
@media (max-width: 767px) {
  .hero .hero-section .hero-wrapper {
    height: 57rem;
  }
  .hero .hero-section .hero-slide .hero-wrapper-slide {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  .hero
    .hero-section
    .hero-slide
    .hero-wrapper-slide
    .wrapper-info
    .wrapper-subtitle {
    font-size: 2rem;
    color: #34a853;
  }
  .hero
    .hero-section
    .hero-slide
    .hero-wrapper-slide
    .wrapper-info
    .wrapper-details {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .hero .hero-section .hero-slide .hero-wrapper-slide .wrapper-img {
    height: 25rem;
    width: auto;
  }
}
@media (max-width: 1200px) {
  .hero .swiper-pagination-bullets {
    bottom: 0;
  }
  .hero .hero-section .hero-slide .hero-wrapper-slide {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  .hero
    .hero-section
    .hero-slide
    .hero-wrapper-slide
    .wrapper-info
    .wrapper-subtitle {
    font-size: 2rem;
    color: #34a853;
  }
  .hero
    .hero-section
    .hero-slide
    .hero-wrapper-slide
    .wrapper-info
    .wrapper-details {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .hero .hero-section .hero-slide .hero-wrapper-slide .wrapper-img {
    height: 25rem;
    width: auto;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1200px) {
  .hero {
    height: auto;
  }
  .hero .hero-section .hero-wrapper {
    height: 70.6rem;
  }
}

.hero-section .swiper {
  width: 100%;
  height: 100%;
  padding: 0rem 0 0 0;
  margin-bottom: -50px;
}
.hero-section .hero-slide {
  padding: 0;
}
.hero-section .hero-slide .hero-wrapper-slide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
}
.hero-section .hero-slide .hero-wrapper-slide .wrapper-info {
  flex: 1 1 0%;
}
.hero-section .hero-slide .hero-wrapper-slide .wrapper-info .wrapper-subtitle {
  font-size: 3.4rem;
  color: #34a853;
}
.hero-section .hero-slide .hero-wrapper-slide .wrapper-info .shop-btn {
  margin: 2rem 0;
  padding: 1.5rem 3rem;
  background-color: #ffbb38;
  color: #1d1d1d;
}
.hero-section .hero-slide .hero-wrapper-slide .wrapper-info .shop-btn svg {
  fill: #1d1d1d;
}
.hero-section .hero-slide .hero-wrapper-slide .wrapper-info .shop-btn:hover {
  color: #ffffff;
}
.hero-section
  .hero-slide
  .hero-wrapper-slide
  .wrapper-info
  .shop-btn:hover
  svg {
  fill: #ffffff;
}
.hero-section .hero-slide .hero-wrapper-slide .wrapper-img {
  height: 60rem;
  width: 65rem;
}
.hero-section .hero-slide .hero-wrapper-slide .wrapper-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.hero-section .hero-service {
  background-color: #34a853;
  padding: 2.8rem;
  border-radius: 4px;
  margin-top: 3rem;
}
.hero-section .hero-service .service-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: unset;
  gap: 2rem;
}
.hero-section .hero-service .service-wrapper .service-info {
  font-size: 1.6rem;
  color: #ffffff;
  margin-bottom: 0;
}
.hero-section .hero-service .service-wrapper .service-details {
  font-size: 1.4rem;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.swiper-slide {
  z-index: 2;
}

.swiper-slide-active {
  z-index: 999;
}

.swiper-pagination {
  text-align: left;
}

.swiper-pagination-bullet {
  width: 2.2rem;
  height: 7px;
  border-radius: 30px;
}

.swiper-pagination-bullet-active {
  background-color: #34a853;
}

.category-section {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 3rem;
}
.category-section .product-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  cursor: pointer;
  box-shadow: none;
  height: auto;
  background-color: transparent;
  padding-bottom: 0;
  border: none;
}
.category-section .product-wrapper .wrapper-img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  width: 100%;
  border-radius: 1.2rem;
  border: 1px solid rgba(52, 168, 83, 0.06);
  transition: all 0.3s;
  background: rgba(52, 168, 83, 0.06);
  height: 19rem;
}
.category-section .product-wrapper .wrapper-img img {
  transition: all 0.2s ease-in-out;
}
.category-section .product-wrapper .wrapper-img:hover {
  border: 1px solid #34a853;
}
.category-section .product-wrapper .wrapper-img:hover img {
  transform: scale(1.2);
}
.category-section .product-wrapper .wrapper-details {
  font-size: 2rem;
  font-weight: 500;
  color: #797979;
}
@media (max-width: 767px) {
  .category-section {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
  }
  .category-section .product-wrapper .wrapper-img {
    height: 15rem;
  }
  .category-section .product-wrapper .wrapper-details {
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1200px) {
  .category-section {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 2rem;
  }
}

.healthy-section {
  padding-top: 6rem;
}
.healthy-section .product-wrapper {
  height: 45.3rem;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0px;
}
.healthy-section .product-wrapper .wrapper-info {
  padding: 2rem 0 0 3rem;
  display: unset;
}
.healthy-section .product-wrapper .wrapper-info .wrapper-details {
  padding: 0 0 2rem 0;
  font-size: 3.4rem;
  margin-bottom: 0;
}
.healthy-section .product-wrapper .wrapper-info .shop-btn {
  margin: 0;
  padding: 1rem 2rem;
}
.healthy-section .product-wrapper .wrapper-info .shop-btn svg {
  fill: #ffffff;
}
.healthy-section .product-wrapper .wrapper-img {
  height: 26rem;
  padding: 0;
  width: 100%;
  flex: 1 1 0%;
  text-align: right;
}
@media (max-width: 767px) {
  .healthy-section .product-wrapper {
    height: auto;
  }
  .healthy-section .product-wrapper .wrapper-details br {
    display: none;
  }
}

.wrapper-subtitle {
  display: block;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

/* .wrapper-one {
  background: url("../assets/images/homepage-one/category-img/c-bg-img-1.webp") no-repeat center/cover;
} */
.wrapper-one .shop-btn {
  padding: 1.2rem 3.4rem;
  margin: 4rem 0;
  text-align: center;
  border-radius: 0.4rem;
  display: inline-block;
  background: #34a853;
  font-size: 1.4rem;
  font-weight: 500;
  position: relative;
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  transition: all 0.3s;
  overflow: hidden;
}
.wrapper-one .shop-btn::after {
  content: ' ';
  width: 0%;
  height: 100%;
  background: #1d1d1d;
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
  top: 0;
  z-index: -3;
}
.wrapper-one .shop-btn svg {
  fill: #ffffff;
  transition: all 0.5s;
}
.wrapper-one .shop-btn span {
  margin-left: 8px;
  font-size: 1.5rem;
  width: 100%;
  z-index: 1;
  transition: all 0.4s ease-in-out;
}
.wrapper-one .shop-btn:hover {
  color: #ffffff;
  background-color: #34a853;
}
.wrapper-one .shop-btn:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}
.wrapper-one .shop-btn:hover svg {
  fill: currentColor;
  fill: #ffffff;
  transform: translate(5px);
}
.wrapper-one .shop-btn:hover span {
  color: #ffffff;
}
.wrapper-one .shop-btn svg {
  fill: #1d1d1d;
}

/* .wrapper-two {
  background: url("../assets/images/homepage-one/category-img/c-bg-img-2.webp") no-repeat center/cover;
} */
.wrapper-two .shop-btn {
  padding: 1.2rem 3.4rem;
  margin: 4rem 0;
  text-align: center;
  border-radius: 0.4rem;
  display: inline-block;
  background: #fe0600;
  font-size: 1.4rem;
  font-weight: 500;
  position: relative;
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  transition: all 0.3s;
  overflow: hidden;
}
.wrapper-two .shop-btn::after {
  content: ' ';
  width: 0%;
  height: 100%;
  background: #1d1d1d;
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
  top: 0;
  z-index: -3;
}
.wrapper-two .shop-btn svg {
  fill: #ffffff;
  transition: all 0.5s;
}
.wrapper-two .shop-btn span {
  margin-left: 8px;
  font-size: 1.5rem;
  width: 100%;
  z-index: 1;
  transition: all 0.4s ease-in-out;
}
.wrapper-two .shop-btn:hover {
  color: #ffffff;
  background-color: #fe0600;
}
.wrapper-two .shop-btn:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}
.wrapper-two .shop-btn:hover svg {
  fill: currentColor;
  fill: #ffffff;
  transform: translate(5px);
}
.wrapper-two .shop-btn:hover span {
  color: #ffffff;
}
/* 
.wrapper-three {
  background: url("../assets/images/homepage-one/category-img/c-bg-img-3.webp") no-repeat center/cover;
} */
.wrapper-three .shop-btn {
  padding: 1.2rem 3.4rem;
  margin: 4rem 0;
  text-align: center;
  border-radius: 0.4rem;
  display: inline-block;
  background: #921dff;
  font-size: 1.4rem;
  font-weight: 500;
  position: relative;
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  transition: all 0.3s;
  overflow: hidden;
}
.wrapper-three .shop-btn::after {
  content: ' ';
  width: 0%;
  height: 100%;
  background: #1d1d1d;
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
  top: 0;
  z-index: -3;
}
.wrapper-three .shop-btn svg {
  fill: #ffffff;
  transition: all 0.5s;
}
.wrapper-three .shop-btn span {
  margin-left: 8px;
  font-size: 1.5rem;
  width: 100%;
  z-index: 1;
  transition: all 0.4s ease-in-out;
}
.wrapper-three .shop-btn:hover {
  color: #ffffff;
  background-color: #921dff;
}
.wrapper-three .shop-btn:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}
.wrapper-three .shop-btn:hover svg {
  fill: currentColor;
  fill: #ffffff;
  transform: translate(5px);
}
.wrapper-three .shop-btn:hover span {
  color: #ffffff;
}

/* .fresh {
  background: url("../assets/images/homepage-one/category-section-background.webp") no-repeat center/cover;
} */

.flash-sale {
  background-color: rgba(255, 255, 255, 0.4);
}

.flash-sale-section {
  /* background: url("../assets/images/homepage-one/flash_sale.webp") no-repeat center/cover; */
  height: 47rem;
  border-radius: 4px;
  padding: 4.7rem 6rem 6rem;
  position: relative;
}
.flash-sale-section .flash-sale-content .wrapper-heading {
  margin-bottom: 0;
}
.flash-sale-section .flash-sale-content .wrapper-details {
  color: #1d1d1d;
  font-size: 1.8rem;
}
.flash-sale-section .flash-sale-content .shop-btn {
  margin: 3rem 0 0 0;
}
.flash-sale-section .discount-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  width: 17.6rem;
  height: 17.6rem;
  position: absolute;
  border-radius: 50%;
  background: #ffbb38;
  color: #1d1d1d;
  text-align: center;
  bottom: 2rem;
  right: 12rem;
}
.flash-sale-section .discount-item span {
  display: block;
}
.flash-sale-section .discount-item .discount-text {
  font-size: 5.4rem;
}
.flash-sale-section .discount-item .discount-inner-text {
  font-size: 3.8rem;
  font-weight: 500;
}
@media (max-width: 767px) {
  .flash-sale-section {
    height: auto;
    padding: 2.5rem;
    background-position: left;
  }
  .flash-sale-section .flash-sale-content .wrapper-heading {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  .flash-sale-section .flash-sale-content .wrapper-details {
    color: #1d1d1d;
    font-size: 1.8rem;
  }
  .flash-sale-section .flash-sale-content .wrapper-details br {
    display: none;
  }
  .flash-sale-section .flash-sale-content .shop-btn {
    margin: 3rem 0 0 0;
  }
  .flash-sale-section .discount-item {
    display: none;
  }
}

.countdown-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: unset;
  gap: 2.3rem;
  margin-bottom: 4rem;
}
.countdown-section .countdown-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.countdown-section .countdown-items span {
  font-weight: 600;
}
.countdown-section .countdown-items .number {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  font-size: 3rem;
  background: #ffffff;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
}
.countdown-section .countdown-items .text {
  font-size: 1.8rem;
  font-weight: 500;
  color: #1d1d1d;
}
@media (max-width: 767px) {
  .countdown-section {
    gap: 1rem;
  }
  .countdown-section .countdown-items span {
    font-weight: 600;
  }
  .countdown-section .countdown-items .number {
    font-size: 1.8rem;
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
  }
  .countdown-section .countdown-items .text {
    font-size: 1.3rem;
  }
}

.flash .flash-sale {
  /* background: url("../assets/images/homepage-one/flash-sale-bg.webp") no-repeat center/cover;
  height: 44rem; */
  border-radius: 0.5rem;
  padding: 6.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0px;
}
.flash .flash-sale .countdown-items .number {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background: #ffffff;
}
.flash .flash-sale .countdown-items .text {
  color: #ffffff;
}
@media (max-width: 767px) {
  .flash .flash-sale {
    justify-content: center;
    height: auto;
  }
  .flash .flash-sale .countdown-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .flash .flash-sale .countdown-section .number {
    font-size: 1.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0px;
    width: 5rem;
    height: 5rem;
  }
  .flash .flash-sale .countdown-section .text {
    font-size: 1.5rem;
  }
}

.top-selling {
  padding-top: 0;
}
@media (max-width: 767px) {
  .top-selling {
    padding-top: 6rem;
  }
}

.top-selling-section .product-wrapper {
  height: 21rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  position: relative;
  padding: 1.5rem;
}
.top-selling-section .product-wrapper .product-img {
  height: 17.4rem;
  width: 16.8rem;
  margin: 0;
  padding: 0;
}
.top-selling-section .product-wrapper .product-info {
  padding: 0;
  margin: 0;
  align-items: flex-start;
  text-align: left;
  flex: 1 1 0%;
}
.top-selling-section .product-wrapper .product-cart-items {
  position: absolute;
  left: 0;
  top: -0.5rem;
  right: unset;
}
.top-selling-section .product-wrapper .price {
  justify-content: flex-start;
  margin: 0 0 2rem 0;
}
.top-selling-section .product-wrapper:hover .product-cart-items {
  left: 1.5rem;
}
@media (max-width: 1200px) {
  .top-selling-section .product-wrapper {
    height: auto;
  }
  .top-selling-section .product-wrapper .product-info {
    align-items: flex-start;
  }
  .top-selling-section .product-wrapper .product-info .product-details {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .top-selling-section .product-wrapper .product-info .product-btn {
    padding: 1.2rem;
  }
  .top-selling-section .product-wrapper .product-img {
    height: 12rem;
    width: 12rem;
  }
}

.grocery-section {
  padding-top: 6rem;
}
.grocery-section .product-wrapper {
  height: 21.5rem;
  border: none;
  background: none;
  padding: 3rem 4rem 4rem;
}
.grocery-section .wrapper-left {
  /* background: url("../discountSection/2.jpg") no-repeat center/cover; */
  background: #c4e3d9;
}
.grocery-section .wrapper-left .shop-btn {
  background: #ffbb38;
  color: #1d1d1d;
  margin: 5rem 0 0 0;
}
.grocery-section .wrapper-left .shop-btn svg {
  fill: #1d1d1d;
}
.grocery-section .wrapper-left .shop-btn:hover {
  color: #ffffff;
}
.grocery-section .wrapper-left .shop-btn:hover svg {
  fill: #ffffff;
}
.grocery-section .wrapper-right {
  /* background: url("../assets/images/homepage-one/grocery-bg-1.webp") no-repeat center/cover; */
  background: #fed9da;
}
@media (max-width: 1023px) {
  .grocery-section .product-wrapper {
    height: 18rem;
    padding: 2rem;
    margin-bottom: 1rem;
    background-position: left;
  }
  .grocery-section .product-wrapper .wrapper-details {
    font-size: 3rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .grocery-section .product-wrapper .wrapper-details br {
    display: none;
  }
  .grocery-section .product-wrapper .shop-btn {
    margin: 4rem 0 0 0;
  }
}

.best-product {
  /* background: url("../assets/images/homepage-one/best-deal-bg.webp") no-repeat center/cover; */
  padding-bottom: 11.2rem;
  height: 36.5rem;
  padding: 8rem 0;
}
.best-product .best-product-section .row > * {
  --bs-gutter-x: 20px;
  --bs-gutter-y: 20px;
}
.best-product .best-product-section .product-wrapper {
  height: auto;
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none;
}
.best-product .best-product-section .product-wrapper .product-info {
  background: none;
  padding: 0;
}
.best-product
  .best-product-section
  .product-wrapper
  .product-info
  .wrapper-details {
  color: #1d1d1d;
  font-size: 1.8rem;
}
.best-product .best-product-section .product-wrapper .product-info .shop-btn {
  margin: 3rem 0 0 0;
}
@media (max-width: 1200px) {
  .best-product {
    height: auto;
    padding: 3rem 2rem;
  }
  .best-product .best-product-section .product-wrapper {
    padding: 0;
  }
}

/* .arrival {
  background: url("../assets/images/homepage-one/new-arrival-bg.webp") no-repeat center/cover;
} */

.modern-grocery-section {
  /* background: url("../assets/images/homepage-one/grocery-bg-2.webp") no-repeat center/cover; */
  height: 28.7rem;
  margin-top: 7rem;
}
.modern-grocery-section .product-wrapper {
  background: transparent;
  border: none;
  box-shadow: none;
  height: auto;
  padding: 8rem 0;
}
.modern-grocery-section .product-wrapper .product-info {
  background: transparent;
}
.modern-grocery-section .product-wrapper .product-info .wrapper-heading {
  color: #ffffff;
}
.modern-grocery-section .product-wrapper .shop-btn {
  margin-top: 2rem;
  background: #ffbb38;
  color: #1d1d1d;
}
.modern-grocery-section .product-wrapper .shop-btn svg {
  fill: #1d1d1d;
}
.modern-grocery-section .product-wrapper .shop-btn:hover {
  color: #ffffff;
}
.modern-grocery-section .product-wrapper .shop-btn:hover svg {
  fill: #ffffff;
}
@media (max-width: 1200px) {
  .modern-grocery-section {
    height: auto;
  }
  .modern-grocery-section .product-wrapper {
    padding: 3rem 1rem;
  }
}

.popular-sale-section .product-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: unset;
  gap: 1.7rem;
  height: auto;
  padding: 1.5rem 1rem;
  border-radius: 0;
  border: none;
}
.popular-sale-section .product-wrapper:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}
.popular-sale-section .product-wrapper .product-img {
  width: 7.5rem;
  height: 7.5rem;
  padding: 0;
  margin: 0;
  border: 1px solid rgba(52, 168, 83, 0.06);
  overflow: hidden;
}
.popular-sale-section .product-wrapper .product-info {
  padding: 0;
}
.popular-sale-section .product-wrapper .product-info .product-details {
  text-align: left;
}
.popular-sale-section .product-wrapper .price {
  justify-content: unset;
  margin: 0;
}
@media (max-width: 767px) {
  .popular-sale-section .product-wrapper .product-info .product-details {
    font-size: 2rem;
  }
}

@media (max-width: 767px) {
  #info-left,
  #info-left-1 {
    padding: 0;
    align-items: flex-start;
    text-align: left;
  }
  #info-left .wrapper-heading,
  #info-left-1 .wrapper-heading {
    font-size: 3.6rem;
    margin-bottom: 1rem;
  }
  #info-left .wrapper-details br,
  #info-left-1 .wrapper-details br {
    display: none;
  }
}
.footer {
  background: #1d1d1d;
  padding-bottom: 0;
}

.footer-padding {
  padding-bottom: 12rem !important;
}

.footer-top-section {
  /* background: url("../assets/images/homepage-one/footer-top-bg.webp") no-repeat center/cover; */
  height: 46rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  margin-top: -17rem;
}
.footer-top-section .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  height: auto;
  padding: 0;
  background-color: transparent;
  text-align: center;
}
.footer-top-section .wrapper .wrapper-title {
  color: #ffffff;
}
.footer-top-section .wrapper .wrapper-title .inner-title {
  color: #ffbb38;
}
.footer-top-section .wrapper .wrapper-paragraph {
  font-size: 1.8rem;
  font-weight: 400;
  margin: 1rem 0;
  color: #ffffff;
}
.footer-top-section .wrapper .footer-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  width: 54.3rem;
  background-color: #ffffff;
  margin-top: 3rem;
  height: 5.4rem;
  border-radius: 4px;
  overflow: hidden;
}
.footer-top-section .wrapper .footer-btn .mail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: unset;
  gap: 0px;
  flex: 1 1 0%;
}
.footer-top-section .wrapper .footer-btn .mail .img {
  padding: 0 1rem 0 2rem;
}
.footer-top-section .wrapper .footer-btn input {
  height: 100%;
  flex: 1 1 0%;
}
.footer-top-section .wrapper .footer-btn input::placeholder {
  color: #222222;
  font-size: 1.2rem;
}
.footer-top-section .wrapper .footer-btn .shop-btn {
  height: 100%;
  border-radius: 0;
  background-color: #ffbb38;
  color: #1d1d1d;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
}
.footer-top-section .wrapper .footer-btn .shop-btn::after {
  display: none;
}
@media (max-width: 767px) {
  .footer-top-section .wrapper .footer-btn {
    width: 30rem;
  }
}

.footer-center hr {
  color: rgba(255, 255, 255, 0.3);
}

.footer-center-section {
  padding-top: 1rem;
}
.footer-center-section .logo {
  margin-bottom: 6rem;
}
.footer-center-section .footer-heading {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #ffffff;
}
.footer-center-section .footer-title {
  margin-bottom: 1.8rem;
}
.footer-center-section .footer-link a {
  color: #c8c8c8;
  font-size: 1.5rem;
  font-weight: 300;
}
.footer-center-section .footer-link a:hover {
  color: #34a853;
  text-decoration: underline;
}
.footer-center-section .footer-link p {
  color: #c8c8c8;
}
.footer-center-section .footer-link ul {
  padding-left: 0;
}
.footer-center-section .footer-link ul li {
  margin-bottom: 2rem;
}
.footer-center-section .footer-link .address {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5rem;
}
.footer-center-section .contact-link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
}
@media (max-width: 767px) {
  .footer-center-section .footer-link a {
    font-size: 1.5rem !important;
  }
}

.footer-bottom-section,
.footer-social,
.social-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
}

.footer-bottom-section {
  padding: 1rem 0 2rem 0;
}
.footer-bottom-section .footer-social {
  gap: 1.5rem;
  line-height: 1.5rem;
}
.footer-bottom-section .footer-social .social-items {
  gap: 2.2rem;
}
.footer-bottom-section .footer-social p {
  color: #c8c8c8;
}
.footer-bottom-section .footer-social .inner-text {
  color: #34a853;
}
@media (max-width: 767px) {
  .footer-bottom-section {
    flex-direction: column;
    gap: 2rem;
  }
}

.product-info {
  background: #ffffff;
}
@media (max-width: 767px) {
  .product-info .product-info-content {
    padding: 0;
  }
}

.product-info-section {
  margin-top: 2.5rem;
}
.product-info-section .row > * {
  --bs-gutter-y: 50px;
}

.product-info-img .swiper {
  position: relative;
}
.product-info-img .swiper .product-discount-content {
  position: absolute;
  top: 2.4rem;
  left: 2.4rem;
  background: #ffbb38;
  width: 8.1rem;
  height: 8.1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  border-radius: 50%;
  z-index: 2;
}
.product-info-img .swiper .product-discount-content h4 {
  font-size: 2rem;
  font-family: 'Inter', sans-serif;
}
.product-info-img .swiper .swiper-wrapper {
  align-items: center;
}
.product-info-img .product-top {
  height: 60rem;
  border: 1px solid rgba(52, 168, 83, 0.06);
  border-radius: 1rem;
}
.product-info-img .product-top .swiper-wrapper {
  height: 100%;
}
.product-info-img .product-top .slider-top-img {
  height: 51.5rem;
  padding: 0;
}
.product-info-img .product-top .slider-top-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 767px) {
  .product-info-img .product-top {
    height: 40rem;
  }
}

.product-bottom {
  margin-top: 2rem;
}
.product-bottom .swiper-slide-thumb-active {
  opacity: 1;
}
.product-bottom .slider-bottom-img {
  height: 9rem !important;
  width: 9rem !important;
  padding: 1rem;
  border: 1px solid rgba(52, 168, 83, 0.06);
  border-radius: 1rem;
  opacity: 0.4;
}
.product-bottom .slider-bottom-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product-bottom .swiper-slide-thumb-active {
  opacity: 1;
}

.product-info-content {
  padding: 0 5rem;
}
.product-info-content .wrapper-subtitle {
  color: #797979;
}
.product-info-content .wrapper-heading {
  font-size: 3rem;
  font-weight: 500;
}
.product-info-content .ratings {
  margin-top: 1.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.product-info-content .ratings .text {
  font-size: 1.4rem;
  color: #1d1d1d;
}
.product-info-content .price {
  gap: 1rem;
  margin-top: 1rem;
  justify-content: flex-start;
}
.product-info-content .price .new-price {
  font-size: 2rem;
  color: #34a853;
}
.product-info-content .content-paragraph {
  line-height: 3rem;
  font-size: 1.6rem;
  margin-top: 1rem;
}
.product-info-content .content-paragraph .inner-text {
  display: block;
}
.product-info-content .product-availability {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 10px 15px;
  background-color: rgba(52, 168, 83, 0.06);
  border-radius: 1.2rem;
  display: inline-block;
  margin: 1rem 0;
}
.product-info-content .product-availability .inner-text {
  color: #34a853;
}
.product-info-content .product-size {
  width: 100%;
  margin-top: 2rem;
  position: relative;
}
.product-info-content .product-size .size-title {
  color: #34a853;
  margin-bottom: 1rem;
}
.product-info-content .product-size .size-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 0.4rem;
  height: 5rem;
  padding: 1.3rem 2.3rem;
  cursor: pointer;
  font-size: 1.3rem;
  color: #797979;
}
.product-info-content .product-size .size-section .chevron {
  transition: 0.3s;
}
.product-info-content .product-size .size-section .toggle-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}
.product-info-content .product-size .size-option {
  position: absolute;
  width: 100%;
  display: none;
  z-index: 2;
  transition: all 0.5s;
}
.product-info-content .product-size .size-option .option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  padding: 13px 23px;
  font-size: 13px;
  transition: all 0.5s;
}
.product-info-content .product-size .size-option .option:hover {
  background: #34a853;
  color: #ffffff;
}
.product-info-content .product-size .size-option .option:hover .option-measure {
  color: #ffffff;
}
.product-info-content .product-size.active .size-option {
  display: block;
  z-index: 2;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  top: calc(100% + 5px);
  overflow: hidden;
}
.product-info-content .product-size.active .size-option .option {
  background-color: #ffffff;
}
.product-info-content .product-size.active .size-option .option:hover {
  background-color: #34a853;
}
.product-info-content .product-size.active .chevron {
  transform: rotate(-180deg);
}
.product-info-content .product-quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.product-info-content .product-quantity .quantity-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.product-info-content .product-quantity .quantity,
.product-info-content .product-quantity .wishlist {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 5rem;
  border-radius: 0.5rem;
}
.product-info-content .product-quantity .quantity {
  width: 12rem;
  font-size: 2rem;
}
.product-info-content .product-quantity .wishlist {
  width: 6rem;
  border: 1px solid rgba(52, 168, 83, 0.15);
}
.product-info-content .product-quantity .shop-btn {
  flex: 1 1 0%;
}
.product-info-content .product-quantity .shop-btn::after {
  display: none;
}
.product-info-content .product-details {
  margin: 1.5rem 0;
}
.product-info-content .product-details p {
  margin-bottom: 1rem;
}
.product-info-content .product-details .inner-text {
  color: #34a853;
}
.product-info-content .product-report {
  margin-bottom: 1.5rem;
}
.product-info-content .product-report .report {
  color: red;
}
.product-info-content .product-report .modal-main {
  border-radius: 1rem;
  margin: 2rem;
}
.product-info-content .product-report .modal-main .review-form {
  width: auto;
  height: auto;
  margin-top: 0;
}
.product-info-content .product-report .modal-main .review-form .review-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
}
.product-info-content
  .product-report
  .modal-main
  .review-form
  .review-content
  .comment-title {
  font-size: 2.4rem;
  margin: 0;
}
.product-info-content
  .product-report
  .modal-main
  .review-form
  .review-content
  .close-btn {
  cursor: pointer;
}
.product-info-content .product-report .modal-wrapper.active {
  display: flex;
}
.product-info-content .product-share,
.product-info-content .social-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}
@media (max-width: 1200px) {
  .product-info-content .product-quantity {
    flex-direction: column;
    margin-top: 2rem;
  }
  .product-info-content .product-quantity .shop-btn {
    margin-top: 0;
  }
}

hr {
  color: rgba(52, 168, 83, 0.06);
}

.product-description .nav-item {
  border-bottom: 1px solid rgba(52, 168, 83, 0.06);
  gap: 2rem;
}
.product-description .nav-item .nav-link {
  font-size: 1.6rem;
  padding: 1rem 2.4rem;
  color: #34a853;
  font-weight: 600;
  margin-bottom: 0;
  border: 0;
}
.product-description .nav-item .nav-link:hover {
  border-color: rgba(52, 168, 83, 0.06);
}
.product-description .nav-item .nav-link.active {
  color: #1d1d1d;
  border-bottom: 1px solid #34a853;
}
.product-description .tab-item {
  padding: 2rem 0 0 0;
}
.product-description .tab-item .product-intro-section {
  margin-bottom: 4rem;
}
.product-description .tab-item .product-intro-section .product-details {
  line-height: 3rem;
  margin-top: 1rem;
}
.product-description .tab-item .product-feature ul li {
  list-style: unset;
  margin-top: 2rem;
  margin-left: 2rem;
}
.product-description .tab-item .product-feature ul li::marker {
  font-size: 1.5rem;
}
.product-description .tab-item .review-wrapper {
  padding: 2rem 0;
}
.product-description .tab-item .review-wrapper .wrapper .wrapper-aurthor {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
}
.product-description
  .tab-item
  .review-wrapper
  .wrapper
  .wrapper-aurthor
  .wrapper-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.product-description
  .tab-item
  .review-wrapper
  .wrapper
  .wrapper-aurthor
  .wrapper-info
  h5 {
  font-size: 2rem;
  font-weight: 500;
}
.product-description .tab-item .review-wrapper .wrapper .wrapper-description {
  margin-top: 2rem;
}
.product-description
  .tab-item
  .review-wrapper
  .wrapper
  .wrapper-description
  .wrapper-details {
  line-height: 3rem;
}

.intro-heading {
  font-weight: 500;
  font-size: 1.8rem;
  font-family: 'Inter', sans-serif;
}

.product-weekly {
  background: #f8f8f8;
}

.sidebar-section {
  background: #ffffff;
  border-radius: 0.5rem;
  padding: 4rem 2rem;
}
.sidebar-section .sidebar-wrapper .wrapper-heading {
  font-size: 1.8rem;
  margin-bottom: 3rem;
  font-weight: 600;
}
.sidebar-section .sidebar-wrapper .sidebar-item .sidebar-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 3rem;
}
.sidebar-section .sidebar-wrapper .sidebar-item .sidebar-list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
.sidebar-section .sidebar-wrapper .sidebar-item .sidebar-list li label {
  font-size: 1.4rem;
  color: #1d1d1d;
}
.sidebar-section hr {
  margin: 4rem 0;
}
.sidebar-section .sidebar-range .price {
  display: unset;
}
.sidebar-section .sidebar-range .price span {
  font-size: 1.2rem;
  color: #1d1d1d;
  font-weight: 500;
}
.sidebar-section .sidebar-range .slider-range {
  height: 6px;
  margin: 2.5rem 0;
  border-radius: 30px;
}
.sidebar-section .sidebar-range .noUi-target {
  background: #d4d4d4;
  box-shadow: none;
  border: none;
}
.sidebar-section .sidebar-range .noUi-handle {
  border-radius: 50%;
  height: 1.6rem;
  width: 1.6rem;
  right: -1.4rem;
  filter: drop-shadow(0px 3px 10px #ccc);
  border: 1 px solid #ccc;
}
.sidebar-section .sidebar-range .noUi-handle::before {
  display: none;
}
.sidebar-section .sidebar-range .noUi-handle::after {
  display: none;
}
@media (max-width: 767px) {
  .sidebar-section .sidebar-range .noUi-handle {
    top: -5px;
  }
}
.sidebar-section .sidebar-range .noUi-connect {
  background: #34a853;
}
.sidebar-section .sidebar-range .example-val {
  font-size: 1.5rem;
}

.sidebar-shop-section {
  /* background: url("../assets/images/homepage-one/sidebar-img.png") no-repeat center/cover; */
  padding: 3rem;
  margin-top: 3rem;
  height: 29.5rem;
  border-radius: 0.5rem;
}
.sidebar-shop-section .wrapper-subtitle {
  font-size: 1.3rem;
  font-weight: 500;
  color: #ffffff;
}
.sidebar-shop-section .wrapper-heading {
  color: #ffffff;
}
@media only screen and (min-width: 767px) and (max-width: 1200px) {
  .sidebar-shop-section {
    display: none;
  }
}

.deal-btn {
  background-color: transparent;
  margin-top: 6rem;
  padding: 0;
  color: #ffffff;
  overflow: unset;
}
.deal-btn::after {
  padding: 0;
  background: #34a853;
  width: 0;
  height: 2px;
  top: 2rem;
  visibility: hidden;
}
.deal-btn:hover {
  background: none;
}
.deal-btn:hover::after {
  width: 100%;
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  background-color: red;
}

.product-sidebar-section .product-sorting-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  background: #ffffff;
  padding: 3rem;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}
.product-sidebar-section .product-sorting-section p {
  font-size: 1.3rem;
  color: #1d1d1d;
}
.product-sidebar-section .product-sorting-section .product-sorting {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: relative;
}
.product-sidebar-section .product-sorting-section .product-sorting span {
  font-size: 1.3rem;
}
.product-sidebar-section .product-sorting-section .product-sorting .default {
  color: #797979;
}
.product-sidebar-section
  .product-sorting-section
  .product-sorting
  .slectbox-body
  .country-options {
  width: auto;
  top: 4rem;
  left: 4rem;
}
@media (max-width: 767px) {
  .product-sidebar-section .product-sorting-section {
    padding: 1rem;
  }
}

.product-deal-section {
  padding: 3rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  /* background: url("../assets/images/homepage-one/product-deal-.webp") no-repeat center/cover; */
}
.product-deal-section .wrapper-heading {
  color: #ffffff;
}
.product-deal-section .shop-btn {
  margin: 0;
  padding: 1.5rem 3rem;
  background: #ffbb38;
  color: #1d1d1d;
}
.product-deal-section .shop-btn::after {
  background-color: #ffbb38;
}

.seller-sidebar .seller-wrapper {
  padding: 4rem;
  align-items: center;
}
.seller-sidebar .seller-wrapper .ratings {
  text-align: center;
  margin-bottom: 0;
}

.blogs-wrapper {
  border: none;
  border-radius: 0;
  height: auto;
  padding: 0;
  overflow: hidden;
}
.blogs-wrapper:hover {
  border: none;
}
.blogs-wrapper:hover img {
  transform: scale(1.1);
}
.blogs-wrapper .wrapper-img {
  overflow: hidden;
}
.blogs-wrapper .wrapper-img img {
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}
.blogs-wrapper .wrapper-info {
  padding: 2rem 3rem;
}
.blogs-wrapper .wrapper-info .wrapper-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4.2rem;
  margin-bottom: 1rem;
}
.blogs-wrapper .wrapper-info .wrapper-data .wrapper-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
}
.blogs-wrapper .wrapper-info .wrapper-data .wrapper-item .icon svg {
  fill: currentColor;
  fill: #34a853;
}
.blogs-wrapper .wrapper-info .wrapper-data .wrapper-item .text {
  font-size: 1.4rem;
  color: #797979;
}
.blogs-wrapper .wrapper-info .divider {
  background: rgba(52, 168, 83, 0.2);
}
.blogs-wrapper .shop-btn {
  margin: 0;
  padding: 0;
  margin: 0;
  text-align: center;
  border-radius: 0.4rem;
  display: inline-block;
  background: transparent;
  font-size: 1.8rem;
  font-weight: 500;
  position: relative;
  color: #34a853;
  font-family: 'Inter', sans-serif;
  transition: all 0.3s;
  overflow: hidden;
  overflow: unset;
}
.blogs-wrapper .shop-btn::after {
  content: ' ';
  width: 0%;
  height: 100%;
  background: #1d1d1d;
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
  top: 0;
  z-index: -3;
}
.blogs-wrapper .shop-btn svg {
  fill: #ffffff;
  transition: all 0.5s;
}
.blogs-wrapper .shop-btn span {
  margin-left: 8px;
  font-size: 1.5rem;
  width: 100%;
  z-index: 1;
  transition: all 0.4s ease-in-out;
}
.blogs-wrapper .shop-btn:hover {
  color: #ffffff;
  background-color: transparent;
}
.blogs-wrapper .shop-btn:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}
.blogs-wrapper .shop-btn:hover svg {
  fill: currentColor;
  fill: #ffffff;
  transform: translate(5px);
}
.blogs-wrapper .shop-btn:hover span {
  color: #ffffff;
}
.blogs-wrapper .shop-btn svg {
  fill: currentColor;
  fill: #34a853;
}
.blogs-wrapper .shop-btn:hover {
  color: #34a853;
}
.blogs-wrapper .shop-btn:hover svg {
  fill: #34a853;
}
.blogs-wrapper .shop-btn::after {
  display: none;
}
@media (max-width: 767px) {
  .blogs-wrapper .wrapper-info {
    padding: 2rem 3rem;
  }
  .blogs-wrapper .wrapper-info .wrapper-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    margin-bottom: 1rem;
  }
}

.blog-details {
  background: #f8f8f8;
}
.blog-details .row > * {
  --bs-gutter-x: 3rem;
}
.blog-details hr {
  margin: 3rem 0;
}
.blog-details .wrapper-img {
  height: 45.7rem;
}
.blog-details .wrapper-img img {
  object-fit: cover;
}
.blog-details .blog-details-heading {
  margin-top: 2rem;
}
.blog-details .blogs-form-section .social-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}
.blog-details .blogs-form-section .social-item a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background: #34a853;
  transition: all 0.2s;
  border: 1px solid #34a853;
}
.blog-details .blogs-form-section .social-item a svg {
  fill: currentColor;
  fill: #ffffff;
}
.blog-details .blogs-form-section .social-item a:hover {
  border-radius: 20%;
  background: #ffffff;
}
.blog-details .blogs-form-section .social-item a:hover svg {
  fill: #34a853;
}
.blog-details .blogs-form-section .review-form {
  padding: 3rem 0 0 0;
}
.blog-details .blogs-form-section .review-form .comment-title {
  text-align: left;
}
@media (max-width: 767px) {
  .blog-details .blogs-wrapper .wrapper-img {
    height: auto;
  }
  .blog-details .blogs-wrapper .wrapper-info {
    padding: 2rem 0;
  }
  .blog-details .blogs-wrapper .wrapper-details {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.review-form .review-form-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  margin-bottom: 2rem;
  position: relative;
}
.review-form .review-form-text img {
  position: absolute;
  top: 4rem;
}
.review-form .comment-title {
  margin-bottom: 3rem;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  text-align: center;
}
.review-form .form-control,
.review-form .form-select {
  border: 1px solid rgba(52, 168, 83, 0.2);
  box-shadow: none;
  color: #1d1d1d;
}
.review-form .form-control::placeholder,
.review-form .form-select::placeholder {
  color: #797979;
  font-family: 'Inter', sans-serif;
}
.review-form label {
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
  color: #797979;
  margin-bottom: 0.5rem;
}
.review-form .review-inner-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.review-form .review-inner-form .review-form-name {
  margin-bottom: 2rem;
  width: 100%;
}
.review-form input {
  height: 5rem;
}
.review-form input,
.review-form textarea,
.review-form select {
  padding: 1rem 2rem;
  font-size: 1.4rem;
}
.review-form input::placeholder,
.review-form textarea::placeholder,
.review-form select::placeholder {
  color: #797979;
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
}
.review-form textarea {
  padding: 3rem;
}
.review-form .review-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0px;
}
.review-form .review-btn .shop-btn {
  width: 30rem;
  margin: 2rem 0 0 0;
}
.review-form .review-btn .shop-btn::after {
  display: none;
}
@media (max-width: 767px) {
  .review-form .comment-title {
    font-size: 2rem;
  }
  .review-form .review-inner-form {
    flex-direction: column;
  }
}

.blog-post-section {
  width: 33.5rem;
}
.blog-post-section .post-details {
  font-size: 2rem;
}
.blog-post-section hr {
  margin: 1.8rem 0;
}
.blog-post-section .blog-post {
  background-color: #ffffff;
  padding: 2.5rem;
  border-radius: 0.6rem;
}
.blog-post-section .blog-post hr {
  color: #34a853;
}
.blog-post-section .blog-post .search-btn {
  background: rgba(52, 168, 83, 0.06);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  padding: 0.8rem 3rem;
  border-radius: 0.6rem;
}
.blog-post-section .blog-post .search-btn input {
  border: none;
  background: transparent;
  padding: 1rem 0;
  outline: none;
}
.blog-post-section .blog-post .search-btn input::placeholder {
  font-size: 1.5rem;
}
.blog-post-section .blog-post .blogs-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
  box-shadow: none;
}
.blog-post-section .blog-post .blogs-wrapper:not(:last-child) {
  margin-bottom: 2rem;
}
.blog-post-section .blog-post .blogs-wrapper .wrapper-img {
  width: 8.5rem;
  height: 9.2rem;
  border-radius: 0.5rem;
}
.blog-post-section .blog-post .blogs-wrapper .wrapper-info {
  padding: 0;
  flex: 1 1 0%;
}
.blog-post-section .blog-post .blogs-wrapper .wrapper-info .wrapper-details {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.4rem;
  margin-bottom: 0;
  font-family: 'Inter', sans-serif;
}
.blog-post-section .blog-post .blogs-wrapper .wrapper-info .wrapper-item {
  margin-top: 1rem;
}
.blog-post-section .blog-post .blogs-wrapper .wrapper-info svg {
  fill: currentColor;
  fill: #34a853;
}
.blog-post-section .blog-post .category-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
}
.blog-post-section .blog-post .category-list a {
  font-size: 1.5rem;
  font-weight: 400;
  color: #797979;
}
.blog-post-section .blog-post .category-list a:hover {
  color: #34a853;
}
.blog-post-section .newsletter {
  /* background: url("../assets/images/homepage-one/newsletter.webp") no-repeat center/cover; */
}
.blog-post-section .newsletter .post-details,
.blog-post-section .newsletter .blog-paragraph {
  color: #ffffff;
}
.blog-post-section .newsletter .blog-paragraph {
  margin: 1rem 0 4rem 0;
}
.blog-post-section .newsletter hr {
  color: #ffffff;
}
.blog-post-section .newsletter input {
  background: #ffffff;
}
.blog-post-section .newsletter input,
.blog-post-section .newsletter .shop-btn {
  width: 100%;
  border-radius: 0.5rem;
  padding: 1.5rem;
  outline: none;
  border: none;
}
.blog-post-section .newsletter .shop-btn {
  margin: 1rem 0;
  background: #ffbb38;
  color: #1d1d1d;
}
.blog-post-section .newsletter .shop-btn::after {
  display: none;
}

@media (max-width: 1023px) {
  .blog-post-section {
    width: 100%;
  }
}
.product-cart {
  background: #ffffff;
}
.product-cart .cart-btn {
  justify-content: center;
}
@media (max-width: 576px) {
  .product-cart .cart-btn {
    flex-direction: column;
  }
  .product-cart .cart-btn .shop-btn {
    width: 100%;
  }
}

.cart-section {
  border: 1px solid rgba(52, 168, 83, 0.3);
  border-radius: 4px;
  overflow: hidden;
}
.cart-section .table-row .table-wrapper .wrapper-img {
  width: 8rem;
  height: 8rem;
  border: 1px solid rgba(52, 168, 83, 0.2);
  border-radius: 0.5rem;
  background: #ffffff;
}
.cart-section .table-row .table-wrapper .wrapper-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cart-section .table-row .table-wrapper .heading {
  font-size: 1.6rem;
}
.cart-section .table-row .table-wrapper .heading:hover {
  color: #34a853;
}
.cart-section .table-row .wrapper-product {
  width: 38rem;
  padding: 2rem 0 2rem 4rem;
}
.cart-section .table-row .wrapper-total {
  width: 20rem;
}
@media (max-width: 1023px) {
  .cart-section {
    overflow-x: scroll;
    border: 1px solid rgba(52, 168, 83, 0.06);
  }
}

table {
  width: 100%;
}
table .ticket-row {
  background: #ffffff;
  transition: all 0.3s;
}
table .ticket-row:hover {
  background-color: rgba(52, 168, 83, 0.06);
}
@media (max-width: 1023px) {
  table .table-wrapper {
    min-width: 25rem;
  }
}

.table-row {
  background: rgba(52, 168, 83, 0.06);
}
.table-row:not(:last-child) {
  border-bottom: 1px solid #e9e7e7;
}
.table-row .table-wrapper {
  padding: 2rem 0;
}
.table-row .table-wrapper .wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}
.table-row .table-wrapper .wrapper-img {
  width: 8rem;
  height: 8rem;
  border: 1px solid rgba(52, 168, 83, 0.06);
  border-radius: 0.5rem;
  background: #ffffff;
}
.table-row .table-wrapper .wrapper-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.table-row .table-wrapper .wrapper-content {
  flex: 1 1 0%;
}
.table-row .table-wrapper .heading {
  font-size: 1.6rem;
  font-weight: 600;
}
.table-row .table-wrapper .heading:hover {
  color: #34a853;
}
.table-row .table-wrapper .table-heading {
  font-size: 1.5rem;
  font-weight: 500;
}
.table-row .table-wrapper p {
  font-weight: 500;
}
.table-row .table-wrapper .ticker-number {
  color: #1d1d1d;
  font-size: 1.8rem;
}
.table-row .table-wrapper .inner-text {
  display: block;
}
.table-row .wrapper-product {
  width: 38rem;
  padding: 2rem 0 2rem 4rem;
}
.table-row .wrapper-total {
  width: 20rem;
}
.table-row .table-wrapper-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  text-align: center;
}
.table-row .table-wrapper-center path {
  fill: currentColor;
  fill: #797979;
  transition: all 0.2s;
}
.table-row .table-wrapper-center path:hover {
  fill: #34a853;
}
.table-row .table-wrapper-img {
  gap: 1rem;
}

.quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 12rem;
  height: 4rem;
  padding: 0 4rem;
  border: 1px solid rgba(52, 168, 83, 0.15);
  border-radius: 0.5rem;
}
.quantity .plus,
.quantity .minus {
  color: #797979;
}
.quantity .number {
  color: #1d1d1d;
}
.quantity span {
  font-size: 1.8rem;
  cursor: pointer;
}

.close-btn svg {
  width: 1rem;
  height: 1rem;
  fill: currentColor;
  fill: #797979;
}
.close-btn svg:hover {
  fill: red;
}

.wishlist-section .table-wrapper {
  width: 38rem;
}
@media (max-width: 767px) {
  .wishlist-section {
    overflow-x: scroll;
  }
}

.wishlist-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  /* margin-top: 2rem; */
}
.wishlist-btn .shop-btn {
  margin: 0;
}
.wishlist-btn .shop-btn::after {
  display: none;
}
.wishlist-btn .clean-btn {
  color: red;
  font-size: 1.5rem;
}
.wishlist-btn .update-btn {
  background-color: #e8e8e8;
  color: #1d1d1d;
}

.checkout {
  background: #ffffff;
  padding-top: 6rem;
}
.checkout .row > * {
  --bs-gutter-x: 3rem;
}
.checkout .billing-section {
  margin-top: 0;
  padding: 4rem;
}
.checkout .billing-section .wrapper-heading {
  margin-bottom: 1rem;
}
.checkout .checkbox-item .form-label {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .checkout .checkout-wrapper .wrapper-heading {
    font-size: 2.5rem;
  }
  .checkout .checkout-wrapper .account-inner-form {
    flex-direction: column;
  }
  .checkout .order-summery .wrapper-heading {
    font-size: 1.8rem;
  }
  .checkout .order-summery .subtotal .product-list li {
    align-items: flex-start;
  }
  .checkout .order-summery .payment-type .wrapper-heading {
    margin-bottom: 1rem;
  }
  .checkout .order-summery .payment-type .checkbox-item {
    padding: 2rem 0;
  }
  .checkout .billing-section {
    padding: 3rem 2rem;
  }
  .checkout .billing-section .review-form {
    padding: 0;
  }
}

.checkout-wrapper .shop-btn {
  margin: 0;
  width: 100%;
  border-radius: 0.5rem;
}
.checkout-wrapper .shop-btn::after {
  display: none;
}

.billing-section {
  background: transparent;
  padding: 2rem;
  margin-top: 2.5rem;
  border: 1px solid rgba(52, 168, 83, 0.06);
  border-radius: 0.5rem;
}
.billing-section .review-form {
  width: auto !important;
  height: auto !important;
}

.order-summery .wrapper-heading {
  font-size: 1.5rem;
  font-weight: 500;
}
.order-summery .subtotal {
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
}
.order-summery .subtotal .product-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 3rem;
  width: 100%;
}
.order-summery .subtotal .product-list li {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
}
.order-summery .subtotal .product-list .product-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}
.order-summery .subtotal .product-list .product-info .wrapper-heading {
  font-size: 1.5rem;
  font-weight: 400;
}
.order-summery .subtotal .product-list .product-info .paragraph {
  font-size: 1.3rem;
}
.order-summery .total .wrapper-heading {
  font-size: 2.5rem;
}
.order-summery .total .price {
  color: #34a853;
}
.order-summery .payment-type {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0px;
}
.order-summery .payment-type .wrapper-heading {
  font-size: 2rem;
}
.order-summery .payment-type .checkbox-item {
  gap: 2rem;
  width: 100%;
  border-radius: 0.5rem;
  transition: all 0.3s;
  padding: 2rem;
}
.order-summery .payment-type .checkbox-item input {
  accent-color: #34a853;
}
.order-summery .payment-type .checkbox-item:hover {
  background-color: rgba(52, 168, 83, 0.06);
}
.order-summery .payment-type .inner-text {
  display: block;
  color: #797979;
}

.order {
  background: #ffffff;
}
.order .order-section {
  margin-top: 3rem;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.order .order-section .review-form {
  width: 100% !important;
  height: auto;
  padding: 0;
  margin-top: 1rem;
}
.order .order-section .review-form .shop-btn {
  width: auto;
}

.login-section .review-form {
  width: 51.2rem;
  height: 63rem;
  background-color: #ffffff;
  padding: 4rem;
  border-radius: 0.5rem;
}
.login-section .review-form .review-inner-form {
  display: block;
  width: 100%;
}
.login-section .review-form .shop-btn {
  width: 100%;
  margin: 0 0 2rem 0;
}
.login-section .review-form .shop-btn::after {
  display: none;
}
.login-section .review-form .shop-account {
  color: #797979;
  font-size: 1.3rem;
}
.login-section .review-form .shop-account a {
  font-size: 1.3rem;
  margin-left: 0.5rem;
  font-weight: 400;
}
.login-section .review-form-text img {
  top: 4rem;
}
@media (max-width: 767px) {
  .login-section .review-form {
    padding: 2rem;
    height: auto;
    width: auto;
  }
  .login-section .review-form .review-form-text img {
    top: 3.5rem;
  }
}
@media (max-width: 1200px) {
  .login-section .review-form {
    width: 100%;
  }
  .login-section .login-img {
    text-align: center;
  }
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
}
.checkbox .forget-pass p {
  color: #34a853;
}

.checkbox-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
.checkbox-item p {
  margin-bottom: 0;
  color: #797979;
}
.checkbox-item p .inner-text {
  color: #1d1d1d;
}
.checkbox-item .address {
  font-size: 1.5rem;
  color: #1d1d1d;
}

.account-section .review-form {
  width: 57.2rem;
}
.account-section .review-form-text img {
  top: 2.5rem;
}
.account-section .account-inner-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}
.account-section .account-inner-form .review-form-name {
  width: 100%;
}
.account-section .city-inner-form {
  margin-bottom: 0;
}
.account-section .address-form {
  margin: 2rem 0;
}
@media (max-width: 767px) {
  .account-section .review-form {
    padding: 2rem;
    height: auto;
  }
  .account-section .account-inner-form {
    flex-direction: column;
  }
  .account-section .checkbox-item {
    margin: 1rem;
  }
}
@media (max-width: 1200px) {
  .account-section .review-form {
    width: 100%;
  }
}

.faq .row > * {
  --bs-gutter-x: 3rem;
}
.faq .question-section .review-form {
  padding: 2rem;
}

.faq-accordion .faq-item {
  margin-top: 3rem;
  border: none;
  --bs-accordion-border-color: $theme-color;
}
.faq-accordion .faq-item .faq-button {
  height: 6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  transition: all 0.5s;
}
.faq-accordion .faq-item .faq-button::after {
  display: none;
}
.faq-accordion .faq-item .faq-button .faq-heading {
  font-size: 1.8rem;
  font-weight: 600;
  color: #1d1d1d;
}
.faq-accordion .faq-item .faq-button:focus {
  border: none;
  box-shadow: none;
}
.faq-accordion .faq-item .faq-button .plus svg {
  fill: currentColor;
  color: #34a853;
}
.faq-accordion .faq-item .faq-button .minus {
  display: none;
  transition: all 1s;
}
.faq-accordion .faq-item .faq-button:not(.collapsed) {
  background: #34a853;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3764705882);
}
.faq-accordion .faq-item .faq-button:not(.collapsed) .plus {
  display: none;
}
.faq-accordion .faq-item .faq-button:not(.collapsed) .minus {
  display: block;
}
.faq-accordion .faq-item .faq-button:not(.collapsed) .minus svg {
  fill: currentColor;
  fill: #ffffff;
}
.faq-accordion .faq-item .faq-button:not(.collapsed) .faq-heading {
  color: #ffffff;
}
.faq-accordion .faq-item .accordion-body {
  padding: 3rem 2rem;
}
.faq-accordion .faq-item .accordion-body .paragraph {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.2rem;
}
.faq-accordion .faq-item .accordion-collapse {
  background-color: #34a853;
}
.faq-accordion .faq-item .accordion-collapse .paragraph {
  color: #ffffff;
}
.faq-accordion .faq-item .faq-heading,
.faq-accordion .faq-item .paragraph {
  font-family: 'Inter', sans-serif;
}

.question-section {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.question-section .review-form {
  width: 100%;
  height: 100%;
  margin-top: 0;
  padding: 2rem 4rem;
}
.question-section .review-form .comment-title {
  margin-top: 2rem;
}
.question-section .review-form .review-form-name {
  margin-bottom: 2rem;
}
.question-section .review-form .shop-btn {
  margin-top: 2rem;
}

.seller-wrapper {
  /* background: url("../assets/images/homepage-one/sallers-cover.png") no-repeat center/cover; */
  min-height: 32.8rem;
  padding: 2.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
}
.seller-wrapper .ratings {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem 0 3rem 0;
}
.seller-wrapper .seller-address .address {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
}
.seller-wrapper .seller-address .address .inner-text {
  font-size: 1.5rem;
}
.seller-wrapper .shop-btn {
  margin: 1rem 0;
}
.seller-wrapper .seller-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  gap: 1rem;
}
.seller-wrapper .seller-details .seller-img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  width: 17rem;
  height: 17rem;
  border-radius: 50%;
  background: #ffffff;
}

@media (max-width: 767px) {
  .seller-wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
  }
  .seller-wrapper .aurthor {
    display: none;
  }
}
.seller-application-section {
  background: #ffffff;
  padding: 3rem 2.5rem;
  border-radius: 0.5rem;
}
.seller-application-section .row > * {
  --bs-gutter-x: 4rem;
}
.seller-application-section .comment-title {
  font-size: 2.2rem;
}
.seller-application-section .paragraph {
  margin-top: 1rem;
}
@media (max-width: 767px) {
  .seller-application-section {
    padding: 2rem 0;
  }
  .seller-application-section .seller-information .review-form .form-btn {
    align-items: center;
  }
}

.seller-information .review-form {
  margin-top: 3rem;
}
.seller-information .review-form .review-inner-form {
  display: block;
}
.seller-information .review-form .review-inner-form .checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
.seller-information .review-form .review-inner-form .checkbox label {
  margin-bottom: 0;
}
.seller-information .review-form .form-btn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0px;
}
.seller-information .review-form .form-btn .shop-btn {
  margin: 0;
  width: 49rem;
}
.seller-information .review-form .form-btn .shop-btn::after {
  display: none;
}
.seller-information .review-form .form-btn .shop-account,
.seller-information .review-form .form-btn a {
  font-size: 1.5rem;
  font-weight: 500;
}
.seller-information .review-form .form-btn .shop-account {
  margin: 1.5rem 0;
  color: #797979;
}
.seller-information .review-form .form-btn .shop-account a {
  margin-left: 0.5rem;
  font-size: 1.5rem;
}
@media (max-width: 767px) {
  .seller-information .review-form .form-btn .shop-btn {
    width: 25rem;
  }
  .seller-information .review-form .form-btn .shop-account {
    text-align: center;
  }
}

.img-upload-section .row > * {
  --bs-gutter-y: 5rem;
}
.img-upload-section .logo-wrapper label {
  background: #34a853;
  border-radius: 50%;
  cursor: pointer;
}
.img-upload-section .logo-wrapper input {
  opacity: 0;
  display: none;
}
.img-upload-section .logo-wrapper .input-item {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  width: 3.2rem;
  height: 3.2rem;
}
.img-upload-section .logo-wrapper .logo-upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  position: relative;
}
.img-upload-section .logo-wrapper .logo-upload .upload-img {
  margin-top: 2rem;
  width: 17rem;
  height: 17rem;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #34a853;
}
.img-upload-section .logo-wrapper .logo-upload .upload-input {
  top: 95%;
  right: 35%;
  transform: translate(-50%, -50%);
}
.img-upload-section .logo-wrapper .cover-upload .cover-img {
  margin-top: 2rem;
  width: 100%;
  height: 15rem;
  border-radius: 0.5rem;
  border: 1px solid #34a853;
}
.img-upload-section .logo-wrapper .cover-upload .cover-input {
  top: 15rem;
  right: 4rem;
}

.privacy-section .intro-heading {
  margin-bottom: 2rem;
}
.privacy-section .policy-details {
  line-height: 3rem;
}
.privacy-section .policy-details .policy-inner-text {
  display: block;
  margin-top: 3rem;
}
.privacy-section .policy {
  margin-bottom: 3rem;
}
.privacy-section .policy .policy-features {
  margin-top: 3rem;
}
.privacy-section .policy .policy-features ul li {
  list-style: unset;
  margin-left: 2rem;
  margin-top: 2rem;
}
.privacy-section .policy .policy-features ul li::marker {
  font-size: 2rem;
}

.paragraph-inner-text {
  display: block;
  margin-top: 3rem;
}

.contact {
  background: #ffffff;
}

.contact-section .row > * {
  --bs-gutter-x: 3rem;
}
.contact-section .wrapper-heading {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.contact-section .contact-information .contact-wrapper {
  margin: 2rem 0;
}
.contact-section .contact-information .contact-wrapper .row > * {
  --bs-gutter-x: 3rem;
}
.contact-section .contact-information .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  border: 1px solid #34a853;
  height: 19.6rem;
  border-radius: 0.5rem;
}
.contact-section .contact-information .wrapper .wrapper-content {
  text-align: center;
}
.contact-section .contact-information .wrapper .wrapper-content .paragraph {
  color: #1d1d1d;
}
.contact-section .contact-information .address {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
  background: rgba(52, 168, 83, 0.06);
  border: 1px solid transparent;
  padding: 2rem;
  border-radius: 0.5rem;
}
.contact-section .contact-information .contact-address {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
}
.contact-section .contact-information .contact-map {
  width: 100%;
}
.contact-section .login-section .review-form .shop-btn::after {
  display: unset;
}
.contact-section .login-section .review-form .shop-account {
  color: #797979;
  font-size: 1.3rem;
}
.contact-section .login-section .review-form .shop-account a {
  font-size: 1.3rem;
  margin-left: 0.5rem;
  font-weight: 400;
}
.contact-section .login-section .review-form-text img {
  top: 4rem;
}
@media (max-width: 767px) {
  .contact-section .login-section .review-form {
    padding: 2rem;
    height: auto;
    width: auto;
  }
  .contact-section .login-section .review-form .review-form-text img {
    top: 3.5rem;
  }
}
@media (max-width: 1200px) {
  .contact-section .login-section .review-form {
    width: 100%;
  }
  .contact-section .login-section .login-img {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .contact-section .contact-information .contact-address {
    flex-direction: column;
  }
  .contact-section .login-section .review-form {
    padding: 2rem 1.5rem;
  }
}

.product-compair .comment-title {
  font-size: 1.8rem;
  font-weight: 500;
}
.product-compair .paragraph {
  margin-top: 1.5rem;
}
.product-compair tbody {
  border: 1px solid rgba(52, 168, 83, 0.06);
}
.product-compair .cart-top {
  background-color: #ffffff;
}
.product-compair .cart-top .cart-item {
  width: 25%;
  padding: 2rem;
}
.product-compair .cart-grey-bg {
  background: #f6f6f6;
}
.product-compair .vertical-cart {
  vertical-align: top;
}
.product-compair .cart-center {
  border: 1px solid rgba(52, 168, 83, 0.06);
}
.product-compair .cart-center .wrapper-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
}
.product-compair .cart-center .search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  border: 1px solid rgba(52, 168, 83, 0.06);
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: #ffffff;
  width: 25rem;
  margin-bottom: 1.8rem;
}
.product-compair .cart-center .search input {
  border: none;
  outline: none;
  color: #34a853;
  flex: 1 1 0%;
}
.product-compair .cart-center .search span svg {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  fill: currentColor;
  fill: #34a853;
}
.product-compair .cart-center .wrapper .wrapper-img {
  width: 16rem;
  height: 16rem;
  margin-bottom: 1rem;
}
.product-compair .cart-center .wrapper .wrapper-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product-compair .cart-center .wrapper .wrapper-details {
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  font-family: 'Inter', sans-serif;
}
.product-compair .cart-center .wrapper .price {
  justify-content: center;
}
.product-compair .cart-center .wrapper .price span {
  font-size: 1.6rem;
}
.product-compair .cart-bottom {
  padding: 1rem;
  border: none;
}
.product-compair .cart-bottom .cart-center {
  border: none;
}
.product-compair .cart-bottom .stock {
  color: green;
  font-weight: 600;
}

.blog {
  /* background: url("../assets/images/homepage-one/page-title-banner.png") no-repeat center/cover; */
  padding: 6rem 0;
}
.blog .heading {
  font-size: 3.6rem;
  text-align: center;
}

.blog-bradcrum span {
  font-size: 1.5rem;
  color: #1d1d1d;
}
.blog-bradcrum a {
  font-size: 1.5rem;
  color: #1d1d1d;
  font-weight: 300;
  font-family: 'Inter', sans-serif;
}
@media (max-width: 767px) {
  .blog-bradcrum {
    text-align: center;
    margin-bottom: 3rem;
  }
  .blog-bradcrum a {
    font-size: 1.5rem;
  }
}

.about {
  padding: 8rem 0 5rem 0;
  background: #ffffff;
}
@media (max-width: 1023px) {
  .about {
    padding: 2rem 0 2rem 0;
  }
}

.about-section .row > * {
  --bs-gutter-x: 6rem;
}
.about-section .about-img {
  position: relative;
}
.about-section .about-img .about-inner-img {
  position: absolute;
  top: 12.3rem;
  z-index: 1;
  right: 10rem;
}
.about-section .about-content .about-info {
  padding: 2rem 0;
}
.about-section .about-content .about-list p {
  color: #1d1d1d;
}
.about-section .about-content .about-list ul li {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
}

.about-service .about-service-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 0px;
}
.about-service .about-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  width: 28.6rem;
  text-align: center;
}
.about-service .seperator {
  width: 1px;
  height: 19.7rem;
  background: rgba(52, 168, 83, 0.06);
}
@media (max-width: 767px) {
  .about-service .seperator {
    display: none;
  }
}
@media (max-width: 1023px) {
  .about-service .seperator {
    display: none;
  }
  .about-service .about-service-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1200px) {
  .about-service .about-service-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }
}

.about-details {
  font-family: 'League Spartan', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  margin-bottom: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.about-details:hover {
  color: #34a853;
}
@media (max-width: 767px) {
  .about-details {
    font-size: 2.6rem;
    line-height: 2.5rem;
  }
}

.about-feedback .section-title {
  justify-content: center;
  margin-bottom: 0;
}
.about-feedback .swiper {
  padding: 3rem 0;
}
.about-feedback .swiper-wrapper {
  box-sizing: border-box;
  margin-left: -11rem;
}
.about-feedback .swiper-slide {
  background: #ffffff;
  padding: 3rem;
  min-height: 27.5rem;
  box-shadow: none;
  border: 1px solid rgba(52, 168, 83, 0.06);
  margin-bottom: 2rem;
  background: rgba(52, 168, 83, 0.1);
}
.about-feedback .swiper-button-next,
.about-feedback .swiper-button-prev {
  position: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  bottom: -10.1rem;
  margin: 0;
  width: 4.8rem;
  height: 4.8rem;
  border: 1px solid #34a853;
  border-radius: 50%;
  transition: all 0.5s;
}
.about-feedback .swiper-button-next::after,
.about-feedback .swiper-button-prev::after {
  display: none;
}
.about-feedback .swiper-button-next svg,
.about-feedback .swiper-button-prev svg {
  stroke: currentColor;
  stroke: #34a853;
  transition: all 0.5s;
}
.about-feedback .swiper-button-next:hover,
.about-feedback .swiper-button-prev:hover {
  background: #34a853;
}
.about-feedback .swiper-button-next:hover svg,
.about-feedback .swiper-button-prev:hover svg {
  stroke: #ffffff;
}
.about-feedback .swiper-buttons {
  position: absolute;
  bottom: -2rem;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}
.about-feedback .swiper-button-next {
  left: 9rem;
}
.about-feedback .swiper-button-prev {
  right: 9rem;
}
@media (max-width: 767px) {
  .about-feedback .swiper-slide {
    padding: 2rem;
    height: auto;
  }
  .about-feedback .swiper-slide .testimonial-details {
    font-size: 1.6rem;
  }
  .about-feedback .swiper-slide .testimonial-details .testimonial-inner-text {
    display: inline;
  }
  .about-feedback .swiper-slide .testimonial-info .testimonial-name {
    font-family: 'League Spartan', sans-serif;
    font-size: 1.8rem;
  }
}
@media (max-width: 1200px) {
  .about-feedback {
    padding: 7rem 0;
  }
  .about-feedback .section-title {
    margin-bottom: 2rem;
  }
  .about-feedback .swiper {
    padding: 0 1rem;
  }
  .about-feedback .swiper-wrapper {
    margin-left: 0;
  }
  .about-feedback .swiper-buttons {
    bottom: -4rem;
  }
}

.about-promotion {
  height: 52.7rem;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
}
.about-promotion::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #34a853;
  z-index: 10;
  opacity: 0.6;
}
.about-promotion video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}
.about-promotion .about-btn {
  height: 10rem;
  width: 10rem;
  background: #ffffff;
  z-index: 100;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  transition: all 0.5s;
  position: relative;
  cursor: pointer;
}
.about-promotion .about-btn::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  border-radius: 50%;
  z-index: -100;
  transition: all 5s;
  animation: btnAnimation 2s linear 0s infinite;
}

.testimonial-wrapper {
  width: 100%;
  height: auto;
  padding: 0 0 2rem 0;
  border-radius: 4px;
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px;
  position: relative;
  cursor: default;
  width: 100%;
}
.testimonial-wrapper .testimonial-details {
  font-size: 1.5rem;
  color: #797979;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 30px;
}
.testimonial-wrapper .ratings {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: unset;
  gap: 1rem;
}
.testimonial-wrapper .ratings .text {
  font-size: 1.5rem;
  font-weight: 500;
}
.testimonial-wrapper .testimonial-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}
.testimonial-wrapper .testimonial-info .testimonial-name {
  font-family: 'Inter', sans-serif;
  font-size: 2.2rem;
}
.testimonial-wrapper .testimonial-info .testimonial-title {
  margin-top: 0.5rem;
}
.testimonial-wrapper .testimonial-info .title-inner {
  color: #34a853;
}
@media (max-width: 1023px) {
  .testimonial-wrapper .testimonial-inner-text {
    display: inline;
  }
}

.divider {
  background: rgba(52, 168, 83, 0.06);
  width: 100%;
  height: 1px;
  border-radius: 50%;
  margin: 2rem 0;
}

.latest .section-title {
  justify-content: center;
}
.latest .section-title .about-details {
  font-size: 3.6rem;
}
.latest .latest-section .row > * {
  --bs-gutter-x: 3.5rem;
}
@media (max-width: 767px) {
  .latest .blogs-wrapper .about-details {
    font-size: 2rem;
  }
  .latest .blogs-wrapper .shop-btn {
    font-size: 1.6rem;
  }
}

.user-profile {
  margin-top: 2rem;
}

.user-profile-section {
  background: #ffffff;
  padding: 4rem 3.6rem;
  border-radius: 1rem;
}
.user-profile-section .dashboard-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  margin-bottom: 2rem;
}
.user-profile-section .dashboard-heading .dashboard-title {
  font-family: 'Inter', sans-serif;
  font-size: 2.2rem;
}
.user-profile-section .dashboard-heading .dashboard-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}
.user-profile-section .dashboard-heading .dashboard-switch .switch-icon {
  width: 7.3rem;
  height: 3.1rem;
  border: 1px solid #c0c0c0;
  border-radius: 4.6rem;
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease-in;
}
.user-profile-section
  .dashboard-heading
  .dashboard-switch
  .switch-icon::before {
  content: '';
  width: 2.3rem;
  height: 2.3rem;
  background: #1d1d1d;
  position: absolute;
  border-radius: 50%;
  top: 3px;
  left: 4px;
  transition: all 0.5s ease-in;
}
.user-profile-section .dashboard-heading .dashboard-switch .switch-icon.active {
  background: #34a853;
}
.user-profile-section
  .dashboard-heading
  .dashboard-switch
  .switch-icon.active::before {
  left: calc(100% - 23px - 4px);
  background-color: #ffffff;
}
.user-profile-section .text {
  color: #797979;
  font-size: 1.6rem;
  transition: all 0.2s;
}
@media (max-width: 1023px) {
  .user-profile-section {
    padding: 3rem 2rem;
  }
  .user-profile-section .dashboard-heading .dashboard-switch .text {
    display: none;
  }
  .user-profile-section
    .dashboard-heading
    .dashboard-switch
    .switch-icon::before {
    width: 2.2rem;
    height: 2.2rem;
    top: 3px;
  }
  .user-profile-section
    .dashboard-heading
    .dashboard-switch
    .switch-icon.active::before {
    left: calc(100% - 2.6rem);
  }
  .user-profile-section .user-dashboard {
    flex-direction: column;
  }
  .user-profile-section .user-dashboard .nav-item {
    width: 100%;
    border-right: none;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .profile-section
    .info-section {
    flex-direction: column;
    gap: 3rem;
    padding: 3rem 0;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .profile-section
    .info-section
    .heading {
    font-size: 2rem;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .profile-section
    .info-section
    .info-list
    p {
    font-size: 1.4rem;
  }
  .user-profile-section .user-dashboard .nav-content .profile-section .devider {
    display: none;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .support-ticket
    .ticket-section {
    overflow-x: scroll;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .seller-application-section {
    padding: 0;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .seller-application-section
    .row
    > * {
    --bs-gutter-x: 0rem;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .seller-application-section
    .account-section
    .review-form {
    padding: 0;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .seller-application-section
    .account-section
    .review-form
    .submit-btn {
    justify-content: center;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .seller-application-section
    .account-section
    .review-form
    .submit-btn
    .shop-btn {
    margin: 2rem 0;
    padding: 1.2rem 2rem;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .payment-section
    .paragraph {
    margin-top: 0.5rem;
  }
  .user-profile-section .user-dashboard .nav-content .payment-section .wrapper {
    flex-direction: column;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .payment-section
    .wrapper
    .wrapper-item {
    flex-direction: column;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .payment-section
    .wrapper
    .shop-btn {
    margin-top: 2rem;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .payment-section
    .wrapper
    .shop-btn::after {
    display: none;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .payment-section
    .wrapper-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0px;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .payment-section
    .wrapper-btn
    .shop-btn {
    padding: 1rem 2rem;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .address-section
    .seller-info {
    background-color: rgba(52, 168, 83, 0.06);
    padding: 2rem;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .address-section
    .seller-info
    .info-list {
    margin-top: 1.5rem;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .address-section
    .seller-info
    .info-list
    p {
    font-size: 1.4rem;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .address-section
    .shop-btn {
    margin: 2rem 0;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .address-section
    .shop-btn::after {
    display: none;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .top-selling-section
    .product-wrapper {
    flex-direction: column;
    height: auto;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .top-selling-section
    .product-wrapper
    .product-img {
    height: 13rem;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .top-selling-section
    .product-wrapper
    .product-img
    img {
    object-fit: contain;
  }
  .user-profile-section
    .user-dashboard
    .nav-content
    .form-section
    .form-btn
    .shop-btn {
    padding: 1rem;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1200px) {
  .user-profile-section
    .dashboard-heading
    .dashboard-switch
    .switch-icon::before {
    width: 2.3rem;
    height: 2.3rem;
    top: 3px;
  }
  .user-profile-section
    .dashboard-heading
    .dashboard-switch
    .switch-icon.active::before {
    left: calc(100% - 34px + 5px);
  }
  .user-profile-section .nav-content .profile-section .info-section {
    flex-direction: row !important;
  }
}
.user-dashboard {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3rem;
}
.user-dashboard .nav-item {
  width: 23.1rem;
  border-right: 2px solid rgba(52, 168, 83, 0.06);
  padding: 0 0 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 3rem;
}
.user-dashboard .nav-item .nav-link {
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
}
.user-dashboard .nav-item .nav-link a span {
  margin-right: 1.6rem;
}
.user-dashboard .nav-item .nav-link svg {
  fill: currentColor;
  fill: #797979;
  transition: all 0.2s;
}
.user-dashboard .nav-item .nav-link.active {
  background-color: transparent;
}
.user-dashboard .nav-item .nav-link.active svg {
  fill: #34a853;
  transform: scale(1.1);
}
.user-dashboard .nav-item .nav-link.active .text {
  transform: translateX(5px);
  color: #34a853;
  font-weight: 600;
}
.user-dashboard .nav-content {
  width: 100%;
}
.user-dashboard .nav-content .paragraph {
  margin-bottom: 1rem;
  color: #1d1d1d;
}
.user-dashboard .nav-content .heading {
  font-size: 2.4rem;
}
.user-dashboard .nav-content .profile-section {
  margin-top: 3rem;
}
.user-dashboard .nav-content .profile-section .product-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
  height: auto;
  padding: 2.6rem;
  background-color: #1d1d1d;
  border: none;
  box-shadow: none;
}
.user-dashboard .nav-content .profile-section .product-wrapper svg,
.user-dashboard .nav-content .profile-section .product-wrapper path {
  fill: currentColor;
  fill: #ffbb38;
}
.user-dashboard .nav-content .profile-section .product-wrapper rect {
  fill: #ffffff;
}
.user-dashboard .nav-content .profile-section .product-wrapper .paragraph {
  font-size: 2rem;
  color: #ffffff;
}
.user-dashboard .nav-content .profile-section .product-wrapper .heading {
  font-size: 4rem;
  color: #ffffff;
}
.user-dashboard .nav-content .profile-section .product-wrapper:hover {
  background: #34a853;
}
.user-dashboard .nav-content .profile-section .product-wrapper:hover svg,
.user-dashboard .nav-content .profile-section .product-wrapper:hover path {
  fill: #34a853;
}
.user-dashboard .nav-content .profile-section .product-wrapper:hover .paragraph,
.user-dashboard .nav-content .profile-section .product-wrapper:hover .heading {
  color: #ffffff;
}
.user-dashboard .nav-content .profile-section .info-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  padding: 4rem;
  background: #f8f8f8;
  border-radius: 0.5rem;
}
.user-dashboard .nav-content .profile-section .info-section .heading {
  margin-bottom: 2rem;
}
.user-dashboard .nav-content .profile-section .info-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}
.user-dashboard .nav-content .profile-section .info-list p {
  font-size: 1.6rem;
}
.user-dashboard .nav-content .profile-section .info-list .info-title,
.user-dashboard .nav-content .profile-section .info-list .info-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
}
.user-dashboard .nav-content .profile-section .info-list .info-details p {
  color: #1d1d1d;
  font-weight: 500;
}
.user-dashboard .nav-content .profile-section .devider {
  height: 16.4rem;
  width: 1px;
  background: #797979;
}
.user-dashboard .nav-content .seller-application-section {
  padding: 0;
}
.user-dashboard .nav-content .seller-application-section .row > * {
  --bs-gutter-x: 3rem;
}
.user-dashboard
  .nav-content
  .seller-application-section
  .account-section
  .review-form {
  width: auto;
  height: auto;
}
.user-dashboard
  .nav-content
  .seller-application-section
  .account-section
  .review-form
  .submit-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}
.user-dashboard
  .nav-content
  .seller-application-section
  .account-section
  .review-form
  .submit-btn
  .shop-btn {
  margin: 2rem 0;
}
.user-dashboard
  .nav-content
  .seller-application-section
  .account-section
  .review-form
  .submit-btn
  .shop-btn::after {
  display: none;
}
.user-dashboard
  .nav-content
  .seller-application-section
  .account-section
  .review-form
  .submit-btn
  .cancel-btn {
  background: transparent;
  color: red;
}
.user-dashboard .nav-content .seller-application-section .img-upload-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
}
.user-dashboard
  .nav-content
  .seller-application-section
  .img-upload-section
  .upload-input {
  transform: translate(-5rem, 7rem);
}
.user-dashboard .nav-content .payment-section .verified {
  color: green;
  font-weight: 500;
}
.user-dashboard .nav-content .payment-section .paragraph {
  margin-top: 0.5rem;
}
.user-dashboard .nav-content .payment-section .wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
}
.user-dashboard .nav-content .payment-section .wrapper .wrapper-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}
.user-dashboard .nav-content .payment-section .wrapper .shop-btn {
  margin: 0;
}
.user-dashboard .nav-content .payment-section .wrapper .shop-btn::after {
  display: none;
}
.user-dashboard .nav-content .payment-section hr {
  margin: 3rem 0;
  color: #34a853;
}
.user-dashboard .nav-content .payment-section .shop-btn {
  margin: 0;
  border: 1px solid #34a853;
}
.user-dashboard .nav-content .payment-section .shop-btn::after {
  display: none;
}
.user-dashboard .nav-content .payment-section .bank-btn {
  margin-left: 2rem;
  background-color: #ffffff;
  color: #1d1d1d;
  border: 1px solid #1d1d1d;
}
.user-dashboard .nav-content .wishlist .cart-content {
  gap: 1rem;
  margin-bottom: 2rem;
  align-items: flex-start;
}
.user-dashboard .nav-content .addresses {
  margin-top: 0;
}
.user-dashboard .nav-content .address-section .seller-info {
  background-color: #f8f8f8;
  padding: 3rem;
}
.user-dashboard .nav-content .address-section .seller-info .info-list {
  margin-top: 1.5rem;
}
.user-dashboard .nav-content .address-section .shop-btn {
  margin: 2rem 0;
}
.user-dashboard .nav-content .address-section .shop-btn::after {
  display: none;
}
.user-dashboard .nav-content .top-selling-section .product-wrapper {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border: none;
}
.user-dashboard
  .nav-content
  .top-selling-section
  .product-wrapper
  .product-img
  img {
  transition: unset;
}
.user-dashboard
  .nav-content
  .top-selling-section
  .product-wrapper
  .product-info {
  flex: 1 1 0%;
}
.user-dashboard
  .nav-content
  .top-selling-section
  .product-wrapper
  .product-info
  .product-details,
.user-dashboard
  .nav-content
  .top-selling-section
  .product-wrapper
  .product-info
  .paragraph {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.user-dashboard
  .nav-content
  .top-selling-section
  .product-wrapper
  .product-info
  .product-details {
  font-size: 2rem;
  -webkit-line-clamp: 1;
}
.user-dashboard
  .nav-content
  .top-selling-section
  .product-wrapper
  .product-info
  .paragraph {
  -webkit-line-clamp: 2;
  color: #797979;
}
.user-dashboard .nav-content .top-selling-section .product-wrapper:hover {
  border: none;
}
.user-dashboard
  .nav-content
  .top-selling-section
  .product-wrapper:hover
  .product-img
  img {
  transform: unset;
}
.user-dashboard .nav-content .top-selling-section .product-cart-btn {
  visibility: visible;
  opacity: 1;
  right: 0;
  width: auto;
}
.user-dashboard
  .nav-content
  .top-selling-section
  .product-cart-btn
  .product-btn {
  padding: 1.2rem 2.5rem;
}
.user-dashboard .nav-content .form-section .form-item {
  margin-bottom: 1.6rem;
}
.user-dashboard .nav-content .form-section .form-item input {
  padding: 1.3rem;
  border-radius: 0;
}
.user-dashboard .nav-content .form-section .form-item .form-label {
  color: #797979;
  font-size: 1.5rem;
}
.user-dashboard .nav-content .form-section .form-item .form-control {
  box-shadow: none;
  border: 1px solid rgb(232, 232, 232);
  border-radius: 0.5rem;
}
.user-dashboard .nav-content .form-section .form-btn {
  margin: 3rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
.user-dashboard .nav-content .form-section .form-btn .shop-btn {
  margin: 0;
  font-weight: 600;
}
.user-dashboard .nav-content .form-section .form-btn .shop-btn::after {
  display: none;
}
.user-dashboard .nav-content .form-section .form-btn .cancel-btn {
  background: transparent;
  color: #1d1d1d;
  border-radius: 0;
}
.user-dashboard .nav-content .support-ticket .shop-btn {
  margin: 0 0 2rem 0;
}
.user-dashboard .nav-content .support-ticket .shop-btn::after {
  display: none;
}
.user-dashboard .nav-content .support-ticket .table-row .table-wrapper {
  padding: 2rem 4rem;
}
.user-dashboard
  .nav-content
  .support-ticket
  .table-row
  .table-wrapper
  .comment-img
  path,
.user-dashboard
  .nav-content
  .support-ticket
  .table-row
  .table-wrapper
  .delete-img
  path {
  fill: currentColor;
}
.user-dashboard
  .nav-content
  .support-ticket
  .table-row
  .table-wrapper
  .comment-img
  path {
  fill: #27ae60;
}
.user-dashboard
  .nav-content
  .support-ticket
  .table-row
  .table-wrapper
  .delete-img
  path {
  fill: #eb5757;
}
.user-dashboard .nav-content .modal-main {
  border-radius: 1rem;
  margin: 2rem;
}
.user-dashboard .nav-content .modal-main .review-form {
  width: auto;
  height: auto;
  margin-top: 0;
}
.user-dashboard .nav-content .modal-main .review-form .review-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
}
.user-dashboard
  .nav-content
  .modal-main
  .review-form
  .review-content
  .comment-title {
  font-size: 2.4rem;
  margin: 0;
}
.user-dashboard
  .nav-content
  .modal-main
  .review-form
  .review-content
  .close-btn {
  cursor: pointer;
}
.user-dashboard .nav-content .modal-wrapper.active {
  display: flex;
} /*# sourceMappingURL=style.css.map */

/* Mobile View */
@media (max-width: 768px) {
  .header-nav {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    /* padding-left: 10px; */
  }

  .header-nav .header-nav-menu-section {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
    /* padding-left: 10px; */
  }

  .header-nav .category-menu-section {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
    margin-right: 10px;
  }

  .logout-button {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    /* gap: 10px; */
    padding-left: 100px;
  }

  .header-top .header-contact .header-top-contact {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
  }
  .header-top .header-contact .header-top-contact .top-contact {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
  }

  /* .header-nav .header-nav-menu .menu-list{
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
    padding-left: 10px;
  } */
}

.pending-add-btn {
  padding: 0.3rem 1.6rem 0.3rem 1.6rem;
}

.sidebar {
  margin-top: 0.5rem !important;
}

.sidebar-sub-category {
  background-color: #eeeeee;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.sidebar-sub-category-selected {
  background-color: #34a853;
  color: white;
}

@media (max-width: 575px) {
  .sidebar {
    margin-top: -1.5rem !important;
  }

  .sidebar-section.box-shadows {
    padding: 1rem 2rem;
  }
  .sidebar h3 {
    text-align: center;
  }
  .sidebar-section .sidebar-wrapper .wrapper-heading {
    margin-bottom: 1rem;
  }
  .sidebar-section .sidebar-wrapper .sidebar-item .sidebar-list {
    gap: 0.5rem;
  }
  .sidebar-section .sidebar-wrapper .sidebar-item .sidebar-list li {
    background-color: #dddddd;
  }
  .wa-button-list-page {
    margin: 1rem 0rem;
  }

  .modal-wrapper {
    margin-left: 0vw;
    margin-top: 0vh;
  }

  .login-section .review-form {
    padding: 1rem;
  }

  .sidebar-sub-category {
    background-color: #eeeeee;
    padding: 0.25rem 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    margin-right: 1rem;
  }

  .sidebar-sub-category-selected {
    background-color: #34a853;
    color: white;
  }
}